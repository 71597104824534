import {TaskData} from "../services/taskService";
import {TableDataFormat} from "../services/workPlanServiice";

export type ActivityL = {
    activityId: number;
    activityName: string;
    objectiveId: number;
    tasks: TaskData[];
    is_complete: boolean;
    dead_line: string;
    created_at: string;
}


export type ObjectiveL = {
    objectiveName: string;
    objectiveId: number;
    is_complete: boolean;
    dead_line: string;
    created_at: string;
    activities: ActivityL[];
};

export const workPlanReStruct = (data: TaskData[]):ObjectiveL[] => {
    // console.log(data)
    let obj = data.map((d) => d.Objective);
    obj = obj.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );


    let act = data.map((d) => d.Activity);

    act = act.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );

    let activities = act.map((a) => {
        let activity:ActivityL = {
            activityId: a.id,
            activityName: a.name,
            objectiveId: a.objective_id,
            is_complete: a.is_complete,
            dead_line: a.dead_line,
            tasks: [],
            created_at: a.created_at,
        };
        data.forEach(d => {
            if (a.id === d.activity_id) {
                activity.tasks.push(d);
            }
        });
        return activity;
    });
    // console.log(activities);

    let objectives = obj.map((ob) => {
        let objective:ObjectiveL = {
            objectiveId: ob.id,
            objectiveName: ob.name,
            is_complete: ob.is_complete,
            dead_line: ob.dead_line,
            activities: [],
            created_at: ob.created_at,
        };
        activities.forEach(activity => {
            if (ob.id === activity.objectiveId) {
                objective.activities.push(activity);
            }
        });
        return objective;
    });
    // console.log(objectives)
    return objectives;
}

export const workPlanPdfGenFormat = (data: TaskData[]): TableDataFormat[] => {
    return data.map((datum, i) => {
        return {
            index: `${i + 1}`,
            objective_name: datum.Objective.name,
            activity_name: datum.Activity.name,
            target: datum.name,
            unit_of_measure: datum.unit_of_measure,
            resource: datum.resource,
            dead_line: new Date(datum.dead_line).toLocaleDateString("en-GB"),
        }
    });
}