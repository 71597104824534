import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {EmployeeDB} from "./employee";

export type ObjectiveData = {
    id: number;
    name: string;
    is_complete: boolean;
    employee_id: {
        Int64: number;
        Valid: boolean
    };
    Employee: EmployeeDB;
    dead_line: string;
    created_at: string;
};

export type ObjectiveInput = {
    name: string;
    employee_id?: number;
    dead_line: Date;
}

export const getObjectives = async () => {
    try {
        return await axios
            .get<ObjectiveData[]>(API_URL + "objectives", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const createObjectiveDB = async (input: ObjectiveInput) => {
    try {
        return await axios
            .post<ObjectiveData>(API_URL + "objectives", input, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};
