import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {EmployeeDB} from "./employee";
import {Department} from "./departmentService";

export type SubDepartment = {
    id: number;
    name: string;
    department_id: number
    employee_id: {
        Int64: number;
        Valid: boolean
    };
    SeniorManager: EmployeeDB;
    Department: Department;
};

export const getSubDepartments = async () => {
    try {
        // console.log(data)
        return await axios
            .get<SubDepartment[]>(API_URL + "sub_departments", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const getSubDepartment = async (param: number) => {
    try {
        return await axios
            .get<SubDepartment>(API_URL + "sub_departments/" + param.toString().trim(), {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const getSubDepartmentByDepartmentID = async (param: number) => {
    try {
        return await axios
            .get<SubDepartment[]>(API_URL + `department/${param.toString().trim()}/sub_departments` , {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};
