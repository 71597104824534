import React from 'react';

const UnitReport = () => {
    return (
        <div>
            Report Coming soon ...
        </div>
    );
};

export default UnitReport;