import React, {useEffect} from 'react';
import {getReportByEmployeeID, ReportData} from "../../services/reportService";
import {useAuth} from "../../context/authContext";
import {useNavigate} from "react-router-dom";
import {IoPersonCircle} from "react-icons/io5";
import {reportTaskReStructure} from "../../utils/reportTaskReStructure";

const DivisionReport = () => {

    const [reports, setReports] = React.useState<ReportData[]>([]);
    const [selectedReport, setSelectedReport] = React.useState<ReportData|undefined>(undefined);

    const {employeeI} = useAuth();

    const navigate = useNavigate();


    useEffect(() => {
        if (!employeeI) navigate("/login")
        fetchData().then();
    },[]);


    const handleChangeReport = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const report = reports.find((report) => report.id === value);
        if (!report) return;
        setSelectedReport(report);
    }

    const fetchData = async () => {
        if (!employeeI) {
            navigate("/login");
            return;
        }
        await getReportByEmployeeID(employeeI.user_id).then(res => {
            if (res) {
                setReports(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedReport(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );
            }
        });
    }


    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 pt-20 pb-32`}>
            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <p>{`${selectedReport?.Employee.fname} ${selectedReport?.Employee.mname.String} ${selectedReport?.Employee.lname}`}</p>
                        <p>{`${selectedReport?.Employee.position}`}</p>
                        <p>Performance Avg: 4.5</p>
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4 items-center justify-center`}>
                    {
                        reports?.length > 0 && (
                            <select
                                onChange={handleChangeReport}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                                {
                                    reports.map((report, i) => (
                                        <option key={i} value={report.id}>{`${report.month.slice(0,3)} - ${report.year} Report`}</option>
                                    ))
                                }
                            </select>
                        )
                    }

                </div>
            </div>

            {/*    Reports   */}
            {
                selectedReport && (
                    <>
                        <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                            <p className={`w-fit text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-gray-500 font-semibold relative`}>
                                {`Report: ${selectedReport.report_number}`}
                                {
                                    (
                                        (selectedReport.manager_approval !== "approved" || selectedReport.sm_approval !== "approved" || selectedReport.director_approval !== "approved")
                                        && !selectedReport.is_report_generated
                                    )
                                        ?
                                        (
                                            <div className={`absolute z-10 w-fit h-fit right-0 top-6 cursor-pointer`}>
                                                <p className={`text-xs text-orange-400 font-semibold`}>Edit</p>
                                            </div>

                                        )
                                        : (
                                            <div></div>
                                        )
                                }
                            </p>
                        </div>

                        {
                            reportTaskReStructure(selectedReport.WorkPlan.Tasks).map((obj, index) => (
                                <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                    <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                                        <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                    </div>
                                    <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                        <div
                                            className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                            <div
                                                className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                <div className={`w-1/4 h-fit`}>Activity</div>
                                                <div
                                                    className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                    <div className={`w-full h-fit col-span-3`}>Target</div>
                                                    <div className={`w-full h-fit col-span-2`}>Status</div>
                                                    <div
                                                        className={`w-full h-fit col-span-2 text-center flex flex-col gap-y-1`}>
                                                        <p className={`w-full text-center`}>Completion</p>
                                                        <div className={`w-full text-sm grid grid-cols-2 gap-x-1`}>
                                                            <div className={`col-span-1`}>Actual</div>
                                                            <div className={`col-span-1`}>Cumulative</div>
                                                        </div>
                                                    </div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Remarks
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                            {
                                                obj.activities.length >= 0 && (
                                                    obj.activities.map((activity, i) => (
                                                        <div key={i}
                                                             className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                            <div
                                                                className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                <div
                                                                    className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                    <p>{activity.activityName}</p>
                                                                </div>
                                                                {
                                                                    activity.tasks.length <= 0 ?
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                <div className={`w-fit h-fit`}>
                                                                                    <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                        targets a located for this
                                                                                        Activity.</p>
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                {
                                                                                    activity.tasks.map((task, i) => (

                                                                                        <div key={i}
                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                {task.taskName}
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.status}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <div
                                                                                                    className={`w-full h-fit text-sm grid grid-cols-2 gap-x-1`}>
                                                                                                    <div
                                                                                                        className={`w-full col-span-1 text-center`}>
                                                                                                        <input
                                                                                                            required={true}
                                                                                                            max={100}
                                                                                                            disabled={true}
                                                                                                            type="text"
                                                                                                            value={`${task.achieved}`}
                                                                                                            className={`w-full py-1.5 px-2 text-center bg-stone-50 outline-0 focus:outline-none focus:ring-0`}/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full col-span-1`}>
                                                                                                        <input
                                                                                                            disabled={true}
                                                                                                            required={true}
                                                                                                            max={100}
                                                                                                            type={"text"}
                                                                                                            value={`${task.cumulative}`}
                                                                                                            className={`w-full text-center py-1.5 px-2 bg-stone-50 outline-0 focus:outline-none focus:ring-0`}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                <p className={`w-full text-center`}>{task.remarks}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>

                                                            <div
                                                                className={`w-full flex flex-row items-center`}>
                                                                <div
                                                                    className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                            </div>


                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <div className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                            <div
                                className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                <div
                                    className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid grid-cols-3 gap-x-6`}>
                                    <div className={`w-full h-fit col-span-1 text-center`}>Scored By</div>
                                    <div className={`w-full h-fit col-span-1 text-center`}>Reviewed By</div>
                                    <div className={`w-full h-fit col-span-1 text-center`}>Approved By</div>
                                </div>
                                <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                <div
                                    className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-3 gap-x-6`}>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{`${selectedReport.Manager.fname} ${selectedReport.Manager.mname.String} ${selectedReport.Manager.lname}`}</p>
                                        <p className={`w-full text-center`}>{`${selectedReport.Manager.position}`}</p>
                                    </div>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{`${selectedReport.SM.fname} ${selectedReport.SM.mname.String} ${selectedReport.SM.lname}`}</p>
                                        <p className={`w-full text-center`}>{`${selectedReport.SM.position}`}</p>
                                    </div>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{`${selectedReport.Director.fname} ${selectedReport.Director.mname.String} ${selectedReport.Director.lname}`}</p>
                                        <p className={`w-full text-center`}>{`${selectedReport.Director.position}`}</p>
                                    </div>
                                </div>

                                <div
                                    className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-3 gap-x-6`}>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        {
                                            selectedReport.manager_approval_at.Valid && (
                                                <p className={`w-full text-center`}>{new Date(selectedReport.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                            )}
                                    </div>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        {
                                            selectedReport.sm_approved_at.Valid && (

                                                <p className={`w-full text-center`}>{new Date(selectedReport.sm_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                            )
                                        }
                                    </div>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        {
                                            selectedReport.director_approved_at.Valid && (

                                                <p className={`w-full text-center`}>{new Date(selectedReport.director_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default DivisionReport;