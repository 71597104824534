import { createContext, useContext, Dispatch, SetStateAction } from "react";
import { TabType } from "../utils/mainTab/tabType";

export const TagContext = createContext<
	{ tag: TabType; setTag: Dispatch<SetStateAction<TabType>> } | undefined
>(undefined);

export const useTagContext = () => {
	const tag = useContext(TagContext);
	if (tag === undefined) {
		throw new Error("Tag should be used on main dashboard");
	}

	return tag;
};
