import React from 'react';

const DivisionGenerateReport = () => {
    return (
        <div>
            Division Generate Report Coming Soon...
        </div>
    );
};

export default DivisionGenerateReport;