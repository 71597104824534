import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";

export type Division = {
    id: number;
    name: string;
    manager_id?: number;
    department_id: number;
    sub_department_id?: number;
};

export const getDivisions = async () => {
    try {
        const data = await axios
            .get<Division[]>(API_URL + "divisions", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
        return data;
    } catch (err) {}
};

export const getAllDivisionsByDepartmentID = async (param: number) => {
    try {
        return await axios
            .get<Division[]>(API_URL + `department/${param.toString().trim()}/divisions`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getAllDivisionsBySubDepartmentID = async (param: number) => {
    try {
        return await axios
            .get<Division[]>(API_URL + `sub_department/${param.toString().trim()}/divisions`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};

export const getDivison = async (param: number) => {
    try {
        const data = await axios
            .get<Division>(API_URL + "divisions/" + param.toString().trim(), {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
        return data;
    } catch (err) {}
};
