import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {ActivityData} from "./activityService";
import {ObjectiveData} from "./objectiveService";
import {EmployeeDB} from "./employee";

export type TaskScoreData = {
    id: number;
    name: string;
    report_id: number;
    task_id: number;
    comment: string;
    supervisor_id: number;
    status: string;
    score: string;
    created_at: string;
    Supervisor: EmployeeDB;
};

export type TaskScoreInput = {
    name: string;
    report_id: number;
    task_id: number;
    comment: string;
    supervisor_id: number;
    status: string;
    score: string;
}

export type TaskScoreReportEditInputs = {
    cumulative_percentage: number;
    achieved_percentage: number;
    remarks: string;
    status: string;
}

export const createTaskScore = async (task: TaskScoreInput) => {
    try {
        return await axios
            .post<number>(API_URL + `task_score`, task, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
}

export const getTasksScore = async () => {
    try {
        return await axios
            .get<TaskScoreData[]>(API_URL + "task_score", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const getTaskScore = async (param: number) => {
    try {
        return await axios
            .get<TaskScoreData>(API_URL + `task_score/${param}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};


export const deleteTaskScore = async (param: number) => {
    try {
        return await axios
            .delete<TaskScoreData>(API_URL + `task_score/${param}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};

