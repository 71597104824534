export type Position = {
    rank: string,
    position: string,
}

export const positions: Position[] = [
    {rank: "CEO", position: "CHIEF EXECUTIVE OFFICER"},
    {rank: "DIRECTOR", position:"DIRECTOR PRODUCT DEVELOPMENT"},
    {rank: "DIRECTOR", position: "DIRECTOR PRODUCTION"},
    {rank: "DIRECTOR", position: "DIRECTOR MARKETING AND SALES"},
    {rank: "DIRECTOR", position: "DIRECTOR QUALITY, HEALTH, SAFETY AND ENVIRONMENT MANAGEMENT"},
    {rank: "DIRECTOR", position: "DIRECTOR FINANCE AND PLANNING"},
    {rank: "DIRECTOR", position: "DIRECTOR HUMAN RESOURCES AND ADMINISTRATION"},
    {rank: "DIRECTOR", position: "DIRECTOR LEGAL AFFAIRS AND COMPLIANCE"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (POWERTRAIN, EE AND INFORMATION SYSTEMS)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (QUALITY INSPECTION, TESTING AND WORKPLACE SAFETY)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (PRODUCT DESIGN, ENGINEERING AND VEHICLE INTEGRATION)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (HEALTH, WELLNESS AND SUSTAINABILITY)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (SALES AND PRODUCT SUPPORT)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (BUSINESS DEVELOPMENT AND CORPORATE AFFAIRS)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (QUALITY & WORKPLACE SAFETY)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (BODY SHOP, ELECTROPHORESIS AND PAINT SHOP)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (CHASSIS SYSTEMS, TRIM AND ASSEMBLY SHOP)"},
    {rank: "SENIOR MANAGER", position: "SENIOR MANAGER (PRODUCTION MANAGEMENT)"},
    {rank:"SENIOR MANAGER",position: "SENIOR MANAGER (BOARD AFFAIRS AND COMPLIANCE)"},
    {rank:"SENIOR MANAGER",position: "SENIOR MANAGER (LEGAL AFFAIRS)"},
    {rank:"MANAGER",position: "CHIEF OF STAFF"},
    {rank: "MANAGER", position: "SHOP FLOOR MANAGER (TRIM AND ASSEMBLY)"},
    {rank: "MANAGER", position: "PRODUCT SUPPORT MANAGER"},
    {rank: "MANAGER", position: "PRODUCT SUPPORT ENGINEERING MANAGER"},
    {rank: "MANAGER", position: "SHOP FLOOR MANAGER (CHASSIS SYSTEMS)"},
    {rank: "MANAGER", position: "SHOP FLOOR MANAGER (BODY)"},
    {rank: "MANAGER", position: "SHOP FLOOR MANAGER (ELECTROPHORESIS AND PAINT)"},
    {rank: "MANAGER", position: "SALES MANAGER"},
    {rank: "MANAGER", position: "INTERIM HEAD OF PRODUCT DESIGN AND ENGINEERING"},
    {rank: "MANAGER", position: "PROCUREMENT MANAGER"},
    {rank: "MANAGER", position: "ADMINISTRATION AND ESTATES MANAGER"},
    {rank: "MANAGER", position: "MANAGER WORK PLACE SAFETY"},
    {rank: "MANAGER", position: "MANAGER CONSUMER EXPERIENCE"},
    {rank: "SENIOR OFFICER", position: "Ag. POWERTRAIN SYSTEMS ENGINEERING MANAGER"},
    {rank: "SENIOR OFFICER", position:"Ag. INFORMATION SYSTEMS MANAGER"},
    {rank: "SENIOR OFFICER", position:"Ag. MANAGER PRODUCTION MANAGEMENT"},
    {rank: "SENIOR OFFICER", position:"Ag. EE SYSTEMS ENGINEERING MANAGER"},
    {rank: "SENIOR OFFICER", position:"Ag. FINANCE MANAGER"},
    {rank: "MANAGER", position: "INTERNAL AUDIT MANAGER"},
    {rank: "PRINCIPAL OFFICER", position: "DEPUTY CHIEF OF STAFF"},
    {rank: "SENIOR OFFICER", position: "SENIOR ACCOUNTANT"},
    {rank: "SENIOR OFFICER", position: "MANAGER WORK PLACE SAFETY"},
    {rank: "SENIOR OFFICER", position: "SENIOR ADMINISTRATION OFFICER"},
    {rank: "SENIOR OFFICER", position: "SENIOR SUPPLIER MANAGEMENT OFFICER"},
    {rank: "SENIOR OFFICER", position: "SENIOR NETWORKS ENGINEER"},
    {rank: "SENIOR OFFICER", position: "SENIOR LEGAL OFFICER"},
    {rank: "SENIOR OFFICER", position: "SENIOR LEGAL OFFICER - COMPLIANCE"},
    {rank: "SENIOR OFFICER", position: "SENIOR LEGAL OFFICER - BOARD AFFAIRS"},
    {rank: "SENIOR OFFICER", position: "SENIOR ADMINISTRATION OFFICER(GOM)"},
    {rank: "SENIOR OFFICER", position: "SENIOR CONSUMER EXPERIENCE SPECIALIST"},
    {rank: "SENIOR OFFICER", position: "SENIOR BUSINESS ANALYST"},
    {rank: "SENIOR OFFICER", position: "SENIOR HUMAN RESOURCES OFFICER"},
    {rank: "SENIOR OFFICER", position: "SENIOR MEDIA STRATEGIST"},
//    {rank: "SENIOR OFFICER", position:"HEAD OF SECURITY"},
    {rank: "SENIOR OFFICER", position:"SENIOR PRODUCT SUPPORT ENGINEER"},
    {rank: "SENIOR OFFICER", position:"INTERIM HEAD - VEHICLE INTEGRATION"},
    {rank: "SENIOR OFFICER", position:"INTERIM HEAD - PRODUCT DESIGN"},
    {rank: "SENIOR OFFICER", position:"INTERIM HEAD - ELECTROPHORESIS AND PAINT SHOP"},
    {rank: "SENIOR OFFICER", position:"ELECTRIC AND ELECTRONIC SYSTEMS ENGINEER-INTERIM HEAD EE SYSTEMS"},
    {rank: "OFFICER", position:"INTERIM HEAD POWERTRAIN SYSTEMS"},
    {rank: "OFFICER", position:"INTERIM HEAD ELECTRIC AND ELECTRONIC SYSTEMS"},
    {rank: "OFFICER", position:"HUMAN RESOURCES OFFICER"},
    {rank: "OFFICER", position:"HUMAN RESOURCES OFFICER - EMPLOYEE RELATION"},
    {rank: "OFFICER", position:"HUMAN RESOURCES OFFICER - TALENT MANAGEMENT"},
    {rank: "OFFICER", position:"HUMAN RESOURCES OFFICER - PERFORMANCE MANAGEMENT"},
    {rank: "OFFICER", position:"PRODUCTION PLANNING AND CONTROL ENGINEER"},
    {rank: "OFFICER", position:"PRODUCT DESIGNER"},
    {rank: "OFFICER", position:"HOMOLOGATION AND APPROVALS ENGINEER"},
    {rank: "OFFICER", position:"CAD ENGINEER"},
    {rank: "OFFICER", position:"PRODUCT LIFECYCLE MANAGEMENT ENGINEER"},
    {rank: "OFFICER", position:"BODY AND CHASSIS SYSTEMS ENGINEER"},
    {rank: "OFFICER", position:"MANUFACTURING ENGINEER"},
    {rank: "OFFICER", position:"MANUFACTURING SYSTEMS ENGINEER"},
    {rank: "OFFICER", position:"QUALITY, INSPECTION AND TESTING ENGINEER"},
    {rank: "OFFICER", position:"ADMINISTRATION OFFICER"},
    {rank: "OFFICER", position:"MAINTENANCE ENGINEER"},
    {rank: "OFFICER", position:"ADMINISTRATION OFFICER(ODPD)"},
    {rank: "OFFICER", position:"ADMINISTRATION OFFICER(ODPN)"},
    {rank: "OFFICER", position:"ADMINISTRATION OFFICER(ODMS)"},
    {rank: "OFFICER", position:"PRODUCT SUPPORT ENGINEER"},
    {rank: "OFFICER", position:"STRATEGY AND PLANNING OFFICER (INVESTMENTS)"},
    {rank: "OFFICER", position:"INTERNAL AUDITOR"},
    {rank: "OFFICER", position:"HEALTH AND WELLNESS OFFICER"},
    {rank: "OFFICER", position:"RECORDS OFFICER"},
    {rank: "OFFICER", position:"IT SYSTEMS ADMINISTRATOR"},
    {rank: "OFFICER", position:"ELECTRIC AND ELECTRONIC SYSTEMS ENGINEER"},
    {rank: "OFFICER", position:"DIGITAL MOBILITY SOLUTIONS ENGINEER"},
    {rank: "OFFICER", position:"CASE VEHICLE TECHNOLOGY ENGINEER"},
    {rank: "OFFICER", position:"POWERTRAIN SYSTEMS ENGINEER"},
    {rank: "OFFICER", position:"INFORMATION SYSTEMS ENGINEER"},
    {rank: "OFFICER", position:"WAREHOUSING OFFICER"},
    {rank: "OFFICER", position:"PROCUREMENT OFFICER"},
    {rank: "OFFICER", position:"NETWORKS ENGINEER"},
    {rank: "OFFICER", position:"SOFTWARE ENGINEER"},
    {rank: "OFFICER", position:"INFORMATION SYSTEMS ENGINEER(SYSTEMS SUPPORT AND ENDPOINT MANAGEMENT)"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - POWERTRAIN SYSTEMS ENGINEERING"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - DESIGN STUDIO AND PROTOTYPING"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - CAD/CAE"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - ELECTRIC AND ELECTRONICS ENGINEERING"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - SOFTWARE ENGINEERING"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - PLATFORM SECURITY ENGINEERING"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - DIGITAL MOBILITY SOLUTIONS ENGINEERING"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - PRODUCT LIFECYCLE MANAGEMENT"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - HOMOLOGATION AND APPROVALS"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - BODY AND CHASSIS SYSTEMS"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - COST ENGINEERING AND ESTIMATION"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - MANUFACTURING SYSTEMS ENGINEERING"},
    {rank: "RESEARCH ASSOCIATE II", position:"RESEARCH ASSOCIATE II - INFORMATION SYSTEMS ENGINEERING"},
    {rank:"SENIOR TECHNICIAN",position:"HEAD OF SECURITY"},
    {rank:"FACILITIES ASSISTANT III",position:"FACILITIES ASSISTANT III (WAREHOUSE)"},
    {rank:"FACILITIES ASSISTANT III",position:"FACILITIES ASSISTANT III (GENERAL STORES)"},
    {rank:"FACILITIES ASSISTANT III",position:"FACILITIES ASSISTANT III (RECORDS)"},
    {rank:"FACILITIES ASSISTANT III",position:"FACILITIES ASSISTANT III (RECORDS MANAGEMENT)"},
    {rank:"FACILITIES ASSISTANT III",position:"FACILITIES ASSISTANT III (PLUMBING SYSTEMS)"},
    {rank:"FACILITIES ASSISTANT III",position:"FACILITIES ASSISTANT III (HVAC SYSTEMS)"},
    {rank:"FACILITIES ASSISTANT II",position:"FACILITIES ASSISTANT II (ADMINISTRATION)"},
    {rank:"FACILITIES ASSISTANT I",position:"FACILITIES ASSISTANT I"},
    {rank:"FACILITIES ASSISTANT I",position:"FACILITIES ASSISTANT I(WELFARE AND ENTERTAINMENT)"},
    {rank: "TECHNICIAN", position:"TECHNICIAN (BODY SHOP)"},
    {rank: "TECHNICIAN", position:"TECHNICIAN (CHASSIS SYSTEMS)"},
    {rank: "TECHNICIAN", position:"TECHNICIAN (QUALITY, INSPECTION AND TESTING)"},
    {rank: "TECHNICIAN", position:"TECHNICIAN (QUALITY, INSPECTION AND TESTING ENGINEER)"},
    {rank: "TECHNICIAN", position:"TECHNICIAN - HVAC"},
    {rank: "TECHNICIAN", position:"TECHNICIAN - PLUMBING"},
    {rank: "TECHNICIAN", position:"TECHNICIAN - PLANT MAINTENANCE"},
    {rank: "TECHNICIAN", position:"TECHNICIAN"},
    {rank: "PLANT OPERATOR IV", position:"PLANT OPERATOR IV"},
    {rank: "PLANT OPERATOR IV", position:"PLANT OPERATOR IV(PLANT MAINTENANCE)"},
    {rank: "PLANT OPERATOR III", position:"PLANT OPERATOR III"},
    {rank: "PLANT OPERATOR III", position:"PLANT OPERATOR III (VEHICLE TEST DRIVER)"},
    {rank: "PLANT OPERATOR II", position:"PLANT OPERATOR II (VEHICLE TEST DRIVER)"},
    {rank: "PLANT OPERATOR II", position:"PLANT OPERATOR II (E-BUS OPERATOR)"},
    {rank: "PLANT OPERATOR II", position:"PLANT OPERATOR II (FORKLIFT OPERATOR)"},
    {rank: "PLANT OPERATOR II", position:"PLANT OPERATOR II (CHASSIS SYSTEMS)"},
    {rank: "PLANT OPERATOR II", position:"PLANT OPERATOR II (BODY SHOP)"},
    {rank: "PLANT OPERATOR II", position:"PLANT OPERATOR II (TRIM)"},
    {rank: "PLANT OPERATOR II", position:"PLANT OPERATOR II (WAREHOUSING AND LOGISTICS)"},

    {rank: "PLANT OPERATOR I", position:"PLANT OPERATOR I"},
    {rank: "PLANT OPERATOR I", position:"PLANT OPERATOR I (PAINT SHOP)"},
    {rank: "PLANT OPERATOR I", position:"PLANT OPERATOR I ELECTROPHORESIS AND PAINT SHOP"},
    {rank: "PLANT OPERATOR I", position:"PLANT OPERATOR I (TRIM)"},
    {rank: "PLANT OPERATOR I", position:"PLANT OPERATOR I (QUALITY, INSPECTION AND TESTING)"},

];

let ranksL = positions.map(p => p.rank)
ranksL = ranksL.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t === item
        ))
);

export const ranks = ranksL;