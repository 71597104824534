import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {TabType} from "../../utils/mainTab/tabType";
import {ButtonType} from "./buttonTypes";

export const ButtonContext = createContext<
    | {
    tag: TabType | undefined;
    buttonType: ButtonType | undefined;
    kind: string,
    setKind: Dispatch<SetStateAction<string>>
    setButtonType: Dispatch<
        SetStateAction<ButtonType | undefined>
    >;
}
    | undefined
>(undefined);

export const useButtonTypeContext = () => {
    const buttonType = useContext(ButtonContext);

    if (buttonType === undefined) {
        throw new Error("Button Type should be used on button ul");
    }
    return buttonType;
};
