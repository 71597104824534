import axios from "axios";
import {
    createContext,
    FC,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
// import {Employee} from "../services/employee";
import {WorkPlanData} from "../../services/workPlanServiice";

// Define types for the authentication context
interface WorkPlanToReportGenContextType {
    workPlanI: WorkPlanData|undefined;
    setWorkPlanI: (emp: WorkPlanData|undefined) => void;
}

const WorkPlanToReportGenContext = createContext<WorkPlanToReportGenContextType | undefined>(undefined);

const WorkPlanToReportGenProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [workPlan, setWorkPlan] = useState<WorkPlanData|undefined>(
        undefined
    );

    const setWorkPlanI = (workP:WorkPlanData|undefined) => {
        setWorkPlan(workP)
    }


    // Provide the authentication context to the children components
    return (
        <WorkPlanToReportGenContext.Provider
            value={{
                workPlanI: workPlan,
                setWorkPlanI
            }}
        >
            {children}
        </WorkPlanToReportGenContext.Provider>
    );
};

export const useWorkPlanToReportGen = (): WorkPlanToReportGenContextType => {
    const context = useContext(WorkPlanToReportGenContext);
    if (!context) {
        throw new Error("useWorkPlanToReportGen must be used within an WorkPlanToReportGenProvider");
    }
    return context;
};

export default WorkPlanToReportGenProvider;
