import React, {ReactElement, useEffect, useState} from 'react';
import {useAuth} from "../context/authContext";
import {Link, useNavigate} from "react-router-dom";
import SideBar from "../components/sideBar";
import {TabType} from "../utils/mainTab/tabType";
import { TabContext } from "../context/tabSwitch/tabContext";
import { TagContext } from "../context/tagContext";
import Main from "../components/main";
import {ButtonType} from "../context/button/buttonTypes";
import {ButtonContext} from "../context/button/buttonContext";
import TabEmployeeProvider from "../context/tabEmployeeContext";
import WorkPlanToReportGenProvider from "../context/workPlanToReport/workPlanToReportGenContext";

export type Links = {
    title: string;
    link: string;
    icon: ReactElement
}


const Dashboard = () => {
    const {employeeI} = useAuth()
    const navigate = useNavigate();

    const [tab, setTab] = useState<TabType>(TabType.SideDashboard);
    const [tag, setTag] = useState<TabType>(TabType.SideDashboard);
    useEffect(() => {
        if (!employeeI) navigate("/login", { replace: true });
    })

    const [buttonType, setButtonType] = useState<ButtonType | undefined>(
        undefined,
    );
    const [kind, setKind] = useState<string>("")

    return (
        <TabEmployeeProvider>
            <WorkPlanToReportGenProvider>
                <TabContext.Provider value={{ tab, setTab }}>
                    <TagContext.Provider value={{ tag, setTag }}>
                        <ButtonContext.Provider
                            value={{
                                kind: kind,
                                tag: tag,
                                buttonType,
                                setButtonType,
                                setKind
                            }}
                        >
                            <div className={`w-full h-full flex flex-row bg-stone-50`}>
                                <div className={`w-1/5 h-full`}>
                                    <SideBar />
                                </div>
                                <div className={`w-4/5 h-full px-4`}>
                                    <Main />
                                </div>
                            </div>
                        </ButtonContext.Provider>
                    </TagContext.Provider>
                </TabContext.Provider>
            </WorkPlanToReportGenProvider>
        </TabEmployeeProvider>
    );
};

export default Dashboard;