import {TaskData} from "../services/taskService";
import {ActivityWRG, ObjectiveWRG} from "../components/employee/employeeGenerateReport";

export const workPlanReportGenRestructure = (data: TaskData[]):ObjectiveWRG[] => {
    // console.log(data)
    let obj = data.map((d) => d.Objective);
    obj = obj.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );


    let act = data.map((d) => d.Activity);

    act = act.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );

    let activities = act.map((a) => {
        let activity:ActivityWRG = {
            activityId: a.id,
            activityName: a.name,
            objectiveId: a.objective_id,
            is_complete: a.is_complete,
            dead_line: a.dead_line,
            tasks: [],
            created_at: a.created_at,
        };
        data.forEach(d => {
            if (a.id === d.activity_id) {
                activity.tasks.push({
                    achieved: d.achieved_percentage,
                    cumulative: d.cumulative_percentage,
                    evidence: [],
                    remarks: d.remarks,
                    status: d.status,
                    hasBeenAdded: false,
                    resource: d.resource,
                    start: d.dead_line,
                    taskId: d.id,
                    taskName: d.name,
                    unit: d.unit_of_measure,
                    onReport: d.on_report

                });
            }
        });
        return activity;
    });
    // console.log(activities);

    // console.log(objectives)
    return obj.map((ob) => {
        let objective: ObjectiveWRG = {
            objectiveId: ob.id,
            objectiveName: ob.name,
            is_complete: ob.is_complete,
            dead_line: ob.dead_line,
            activities: [],
            created_at: ob.created_at,
        };
        activities.forEach(activity => {
            if (ob.id === activity.objectiveId) {
                objective.activities.push(activity);
            }
        });
        return objective;
    });
}