import {TabType} from "./tabType";
import IndividualDashboard from "../../components/dashboards/IndividualDashboard";
import DepartmentDashboard from "../../components/dashboards/departmentDashboard";
import DivisionDashboard from "../../components/dashboards/divisionDashboard";
import {EmployeeDB} from "../../services/employee";
import EmployeeSummary from "../../components/employee/employeeSummary";
import DepartmentSummary from "../../components/departments/departmentSummary";
import DivisionSummary from "../../components/divisions/divisionSummary";
import UnitSummary from "../../components/unit/unitSummary";
import UnitDashboard from "../../components/dashboards/unitDashboard";
import SubDepartmentDashboard from "../../components/dashboards/subDepartmentDashboard";
import SubDepartmentSummary from "../../components/subDepartment/subDepartmentSummary";


export const sideBarTabSelector = (tab: TabType, role: string, employee?: EmployeeDB): React.ReactNode => {
	switch (tab) {
		case TabType.SideDashboard:
			return role === "department_head" || role === "ceo" ? <DepartmentDashboard/> : role === "sub_department_head" ?
				<SubDepartmentDashboard/> : role === "division_head" ? <DivisionDashboard/> : role === "unit_head" ? <UnitDashboard /> : <IndividualDashboard/>;
		case TabType.SideEmployee:
			if (!employee) {
				return role === "department_head" || role === "ceo" ? <DepartmentDashboard/> : role === "sub_department_head" ?
					<SubDepartmentDashboard/> : role === "division_head" ? <DivisionDashboard/> : role === "unit_head" ? <UnitDashboard /> : <IndividualDashboard/>;
			}
			if (employee.role === "department_head" || employee.role === "ceo" ) {
				return <DepartmentSummary employee={employee}/>
			} else if (employee.role === "sub_department_head" ) {
				return <SubDepartmentSummary employee={employee}/>
			} else if (employee.role === "division_head") {
				return <DivisionSummary employee={employee} />;
			}else if (employee.role === "unit_head") {
				return <UnitSummary employee={employee} />;
			}
			return <EmployeeSummary employee={employee} />;

		default:
			return role === "department_head" || role === "ceo" ? <DepartmentDashboard/> : role === "sub_department_head" ?
				<DepartmentDashboard/> : role === "division_head" ? <DivisionDashboard/> : role === "unit_head" ? <UnitDashboard /> : <IndividualDashboard/>;
	}
};
