import React from 'react';

const GenerateDepartmentReport = () => {
    return (
        <div>
            Generate Report Coming Soon...
        </div>
    );
};

export default GenerateDepartmentReport;