import React, {useState} from 'react';
import {useTagContext} from "../context/tagContext";
import {useTabContext} from "../context/tabSwitch/tabContext";
import {useAuth} from "../context/authContext";
import {IoPerson, IoPersonCircleOutline} from "react-icons/io5";
import {LuLogOut} from "react-icons/lu";
import {useButtonTypeContext} from "../context/button/buttonContext";
import {ButtonType} from "../context/button/buttonTypes";
import {buttonSelector} from "../context/button/uttonSelector";
import {sideBarTabSelector} from "../utils/mainTab/tabSelector";
import {Link} from "react-router-dom";
import {useTabEmployee} from "../context/tabEmployeeContext";
import {TabType} from "../utils/mainTab/tabType";

const Main = () => {
    const { tab, setTab } = useTabContext();
    const { tag, setTag } = useTagContext();
    const {buttonType,kind, setKind, setButtonType} = useButtonTypeContext();
    const {employeeI} = useAuth();
    const {tabEmployee, setTabEmployee} = useTabEmployee();
    const [toggle, setToggle] = useState<boolean>(false);
    return (
        <div className={`w-full h-full text-stone-900 relative`}>
            { employeeI && (
                buttonType !== undefined && kind.length !== 0 ? buttonSelector(employeeI.role, buttonType, kind) : sideBarTabSelector(tab, employeeI.role, tabEmployee)
                )
            }
            <div onClick={() => {setToggle(!toggle)}} className={`absolute w-fit h-fit top-4 right-4`}>
                <IoPerson className={`text-stone-400 w-5 h-5`} />
            </div>
            {
                toggle && (
                    <div
                        className={`absolute w-32 rounded-md bg-white h-fit py-2 px-4 top-12 right-2 flex flex-col gap-1.5 shadow-sm shadow-stone-800/60`}>
                        <div onClick={() => {
                            setTab(TabType.SideDashboard);
                            setTag(TabType.SideDashboard);
                            setTabEmployee(undefined);
                            setKind("")
                            setButtonType(undefined)
                            setToggle(false)
                        }}
                            className={`w-full h-fit flex flex-row items-center justify-between gap-2  cursor-pointer`}>
                            <p className={`text-xs`}>My Summary</p>
                            <IoPersonCircleOutline className={`text-stone-700 w-4 h-4`}/>
                        </div>
                        <Link to={"/logout"} >
                            <div className={`w-full h-fit flex flex-row items-center justify-between gap-2 cursor-pointer`}>
                                <p className={`text-xs`}>Logout</p>
                                <LuLogOut className={`text-stone-700 w-4 h-4`}/>
                            </div>
                        </Link>
                    </div>
                )
            }
        </div>
    );
};

export default Main;