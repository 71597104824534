import React, {FC, useState} from 'react';
import {PDF_HOST} from "../../utils/api";

const PdfRender:FC<{pdf: string}> = ({pdf}) => {

    return (
        <div className="w-full h-full">
           <iframe src={pdf} width="100%" height="100%"/>
        </div>
    );
};

export default PdfRender;