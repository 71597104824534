import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";

export type Department = {
    id: number;
    name: string;
    director_id?: number;
};

export const getDepartments = async () => {
    try {
        const data = await axios
            .get<Department[]>(API_URL + "departments", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
        // console.log(data)
        return data;
    } catch (err) {}
};


export const getDepartment = async (param: number) => {
    try {
        return await axios
            .get<Department>(API_URL + "departments/" + param.toString().trim(), {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};
