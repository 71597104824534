import React from 'react';
import {Link} from "react-router-dom";
import {Employee, forgotPasswordService, loginService} from "../services/employee";
import {AxiosError} from "axios";

export type Msg = {
    msg: string;
}

const ForgotPassword = () => {
    const [email, setEmail] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");

    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        setError("");

        if (email.length <= 0) {
            setError("Email is required");
            setLoading(false);
            return;
        }

        try {
            const result = await forgotPasswordService(email)
            // console.log(result)
            const data: Msg = result.data;
            setSuccess(data.msg);
            // navigate("/dashboard", { replace: true });

            // console.log(result.data)
        } catch (error) {
            // if (error) {
            //     // @ts-ignore
            //     setError(error.response.data.error);
            //     return;
            // }
            if (error instanceof AxiosError) {
                setError(error.response?.data?.error);
                setLoading(false);
                return;
            }
            setError("Something went wrong!");

        }

    }

    return (
        <div className={`w-full h-full`}>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-20">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        alt="Your Company"
                        src="https://kmcwebsite.nyc3.cdn.digitaloceanspaces.com/new%20logos/Asset%205.webp"
                        className="mx-auto h-10 w-auto"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Forgot Password
                    </h2>
                    {
                        (loading && success.length <= 0) && (
                            <p>Loading...</p>
                        )
                    }
                    {
                        error.length > 0 && (
                            <div className={`w-full h-fit mt-4 bg-red-500/20 rounded-sm`}>
                                <p className={`w-full text-center px-2 py-1.5  ring-1 ring-red-500 text-sm text-red-500 font-semibold rounded-sm`}>{error}</p>
                            </div>
                        )
                    }
                    {
                        success.length > 0 && (
                            <div className={`w-full h-fit mt-4 bg-emerald-500/20 rounded-sm`}>
                                <p className={`w-full text-center px-2 py-1.5  ring-1 ring-emerald-500 text-sm text-emerald-500 font-semibold rounded-sm`}>{success}</p>
                            </div>
                        )
                    }

                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={submitHandler} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <p className="mt-1 text-sm/6 text-gray-600">
                                Please provide the email address associated with your account.
                            </p>

                            <div className="mt-2">
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    required
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                disabled={loading}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                            >
                                Request Password Change
                            </button>
                        </div>
                    </form>


                    <Link to="/login">
                        <p className="mt-4 text-center text-sm text-gray-500"> Try again </p>
                    </Link>

                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;