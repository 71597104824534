import React from "react";
import {ButtonType} from "./buttonTypes";
import DepartmentWorkPlan from "../../components/departments/departmentWorkPlan";
import EmployeeWorkPlan from "../../components/employee/employeeWorkPlan";
import DivisionWorkPlan from "../../components/divisions/divisionWorkplan";
import DepartmentReport from "../../components/departments/departmentReport";
import DivisionReport from "../../components/divisions/divisionReport";
import EmployeeReport from "../../components/employee/employeeReport";
import CreateDepartmentWorkPlan from "../../components/departments/createDepartmentWorkPlan";
import CreateDivisionWorkPlane from "../../components/divisions/createDivisionWorkPlane";
import CreateEmployeeWorkPlan from "../../components/employee/createEmployeeWorkPlan";
import GenerateDepartmentReport from "../../components/departments/generateDepartmentReport";
import DivisionGenerateReport from "../../components/divisions/divisionGenerateReport";
import EmployeeGenerateReport from "../../components/employee/employeeGenerateReport";
import EmployeeEditWorkPlan from "../../components/employee/employeeEditWorkPlan";
import DivisionWorkplanEdit from "../../components/divisions/divisionWorkplanEdit";
import DepartmentWorkPlanEdit from "../../components/departments/departmentWorkPlanEdit";
import UnitReport from "../../components/unit/unitReport";
import UnitGenerateReport from "../../components/unit/unitGenerateReport";
import UnitEditWorkPlan from "../../components/unit/unitEditWorkPlan";
import CreateUnitWorkPlan from "../../components/unit/createUnitWorkPlan";
import UnitWorkPlan from "../../components/unit/unitWorkPlan";
import SubDepartmentWorkPlan from "../../components/subDepartment/subDepartmentWorkPlan";
import SubDepartmentReport from "../../components/subDepartment/subDepartmentReport";
import CreateSubDepartmentWorkPlan from "../../components/subDepartment/createWorkPlan";
import SubDepartmentGenerateReport from "../../components/subDepartment/subDepartmentGenerateReport";
import SubDepartmentWorkPlanEdit from "../../components/subDepartment/subDepartmentEditWorkPlan";

export const buttonSelector = (role: string, tab: ButtonType, kind: string): React.ReactNode => {
    switch (tab) {
        case ButtonType.WorkPlan:
            return kind === "department" ? <DepartmentWorkPlan /> : kind === "sub_department" ? <SubDepartmentWorkPlan /> : kind === "division" ? <DivisionWorkPlan /> : kind === "unit" ? <UnitWorkPlan /> : <EmployeeWorkPlan />;
        case ButtonType.Report:
            let reportElement = <EmployeeReport />
            if (kind === "department") {
                reportElement = <DepartmentReport />
            }if (kind === "sub_department") {
                reportElement = <SubDepartmentReport />
            } else if (kind === "division") {
                reportElement = <DivisionReport />
            } else if (kind === "individual") {
                reportElement = <EmployeeReport />
            } else if (kind === "unit") {
                reportElement = <UnitReport />
            } else {
                reportElement = <EmployeeReport />
            }
            return reportElement
        case ButtonType.CreateWorkPlan:
            return role === "department_head" || role === "ceo" ? <CreateDepartmentWorkPlan/> : role === "sub_department_head" ?
                <CreateSubDepartmentWorkPlan/> : role === "division_head" ? <CreateDivisionWorkPlane /> : role === "unit_head" ? <CreateUnitWorkPlan /> : <CreateEmployeeWorkPlan/>;
        case ButtonType.GenerateReport:
            return role === "department_head" || role === "ceo" ? <GenerateDepartmentReport/> : role === "sub_department_head" ?
                <SubDepartmentGenerateReport/> : role=== "division_head" ? <DivisionGenerateReport /> : role === "unit_head" ? <UnitGenerateReport /> : <EmployeeGenerateReport/>;

        case ButtonType.EditWorkPlan:
            return role === "department_head" || role === "ceo" ? <DepartmentWorkPlanEdit/> : role === "sub_department_head" ?
                <SubDepartmentWorkPlanEdit/> : role === "division_head" ? <DivisionWorkplanEdit /> : role === "unit_head" ? <UnitEditWorkPlan /> :<EmployeeEditWorkPlan/>;

        default:
            return;
    }
};
