import {TaskScoreData} from "../services/taskScoreService";
import {TaskData} from "../services/taskService";
import {TableDataFormat} from "../services/workPlanServiice";
import {TableDataReportFormat} from "../services/reportService";
import {scoreColour, statusScore} from "./scoreUtil";

export type ObjectiveRTR = {
    objectiveName: string;
    objectiveId: number;
    is_complete: boolean;
    dead_line: string;
    created_at: string;
    activities: ActivityRTR[];
};

export type ActivityRTR = {
    activityId: number;
    activityName: string;
    objectiveId: number;
    tasks: TaskRTR[];
    is_complete: boolean;
    dead_line: string;
    created_at: string;
}

export type TaskRTR = {
    taskId: number;
    taskName: string;
    unit: string;
    resource: string;
    start: string,
    status: string,
    remarks: string,
    cumulative?: number,
    achieved?: number,
    hasBeenAdded: boolean,
    onReport: boolean,
    taskScore: TaskScoreData,
    evidence: EvidenceRTR[];
}

export type EvidenceRTR = {
    evidenceId?: number;
    evidence?: string;
    taskId?: number;
    type: string;
}

export const reportTaskReStructure = (data: TaskData[]):ObjectiveRTR[] => {
    // console.log(data)
    let obj = data.map((d) => d.Objective);
    obj = obj.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );


    let act = data.map((d) => d.Activity);

    act = act.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
    );

    let activities = act.map((a) => {
        let activity:ActivityRTR = {
            activityId: a.id,
            activityName: a.name,
            objectiveId: a.objective_id,
            is_complete: a.is_complete,
            dead_line: a.dead_line,
            tasks: [],
            created_at: a.created_at,
        };
        data.forEach(d => {
            if (a.id === d.activity_id && d.on_report) {
                activity.tasks.push({
                    taskScore: d.TaskScore,
                    achieved: d.achieved_percentage,
                    cumulative: d.cumulative_percentage,
                    evidence: [],
                    remarks: d.remarks,
                    status: d.status,
                    hasBeenAdded: false,
                    resource: d.resource,
                    start: d.dead_line,
                    taskId: d.id,
                    taskName: d.name,
                    unit: d.unit_of_measure,
                    onReport: d.on_report

                });
            }
        });
        return activity;
    });
    // console.log(activities);

    // console.log(objectives)
    return obj.map((ob) => {
        let objective: ObjectiveRTR = {
            objectiveId: ob.id,
            objectiveName: ob.name,
            is_complete: ob.is_complete,
            dead_line: ob.dead_line,
            activities: [],
            created_at: ob.created_at,
        };
        activities.forEach(activity => {
            if (ob.id === activity.objectiveId) {
                if (activity.tasks.length > 0) {
                    objective.activities.push(activity);
                }
            }
        });
        return objective;
    });
}


export const reportPdfGenFormat = (data: TaskData[]): TableDataReportFormat[] => {
    return data.map((datum, i) => {
        return {
            index: `${i + 1}`,
            objective_name: datum.Objective.name,
            activity_name: datum.Activity.name,
            target: datum.name,
            unit_of_measure: datum.unit_of_measure,
            resource: datum.resource,
            status: datum.status,
            score: datum.TaskScore.score,
            score_status: statusScore(datum.TaskScore.status),
            bg_color: scoreColour(datum.TaskScore.score),
            remarks: datum.remarks,
            supervisor_remarks: datum.TaskScore.comment,
            achieved: datum.achieved_percentage ? datum.achieved_percentage : 0,
            cumulative: datum.cumulative_percentage ? datum.cumulative_percentage : 0,
            dead_line: new Date(datum.dead_line).toLocaleDateString("en-GB"),
        }
    });
}