import React, {useEffect, useState} from 'react';
import logo from "../img/logo.png";
import {useTabContext} from "../context/tabSwitch/tabContext";
import {TabType} from "../utils/mainTab/tabType";
import {useTagContext} from "../context/tagContext";
import {TfiDashboard} from "react-icons/tfi";
import {useAuth} from "../context/authContext";
import {ImTree} from "react-icons/im";
import {MdDashboardCustomize} from "react-icons/md";
import {
    Division,
    getAllDivisionsByDepartmentID,
    getAllDivisionsBySubDepartmentID,
    getDivisions
} from "../services/divisionService";
import {useNavigate} from "react-router-dom";
import {Department, getDepartments} from "../services/departmentService";
import {
    Employee,
    EmployeeDB,
    getAllUsersBYDepartmentID,
    getAllUsersBYDivisionID, getAllUsersBYSubDepartmentID, getAllUsersBYUnitID,
    getEmployees
} from "../services/employee";
import {useButtonTypeContext} from "../context/button/buttonContext";
import {useTabEmployee} from "../context/tabEmployeeContext";
import {getUnits, Unit} from "../services/unitService";
import {getSubDepartmentByDepartmentID, getSubDepartments, SubDepartment} from "../services/subDepartment";

const SideBar = () => {
    const [units, setUnits] = useState<Unit[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [subDepartments, setSubDepartments] = useState<SubDepartment[]>([]);
    const [employees, setEmployees] = useState<EmployeeDB[]>([]);
    const {setTabEmployee} = useTabEmployee();
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedTab, setSelectedTab] = useState<string>("");

    const { setTab, tab } = useTabContext();
    const {setKind, setButtonType} = useButtonTypeContext();
    const { setTag } = useTagContext();
    const {employeeI} = useAuth()
    const navigate = useNavigate();

    useEffect(() => {
        fetchAll().then()
    },[])

    const fetchAll = async () => {
        if (!employeeI) {
            if (!employeeI) navigate("/login", { replace: true });
            return
        }
        if (employeeI.role === "ceo") {
            await getDivisions().then(res => {
                if (res) {
                    setDivisions(res);
                }
            });
            await getDepartments().then(res => {
                if (res) {
                    setDepartments(res);
                }
            })
        } else {
          await getAllDivisionsByDepartmentID(employeeI.department).then(res => {
              if (res) {
                  setDivisions(res);
              }
          })
        }

        await getUnits().then(res => {
            if (res) {
                setUnits(res);
            }
        })

        if (employeeI.role === "ceo") {
            await getEmployees().then(res => {
                if (res) {
                    setEmployees(res.filter(r => r.role !== employeeI.role));
                }
            })
            await getSubDepartments().then(res => {
                if (res) {
                    setSubDepartments(res);
                }
            })
        } else if (employeeI.role === "department_head") {
            await getAllUsersBYDepartmentID(employeeI.department).then(res => {
                if (res) {
                    setEmployees(res.filter(r => !(r.role === "department_head")));
                }
            })
            await getSubDepartmentByDepartmentID(employeeI.department).then(res => {
                if (res) {
                    setSubDepartments(res);
                }
            })
            await getAllDivisionsByDepartmentID(employeeI.department).then(res => {
                if (res) {
                    setDivisions(res);
                }
            })
        }else if (employeeI.role === "sub_department_head") {
            if (!employeeI.sub_department || employeeI.sub_department === 0) return
            // await getAllUsersBYSubDepartmentID(employeeI.sub_department).then(res => {
            //     if (res) {
            //         setEmployees(res.filter(r => !(r.role === "department_head" || r.role === "sub_department_head")));
            //     }
            // })
             await getEmployees().then(res => {
                 if (res) {
                     setEmployees(res.filter(r => {
                         return (r.sub_department_id.Int64|| (employeeI.department === 6 && r.role === "admin_hod")) && (r.role !== "department_head" && r.role !== "sub_department_head");

                     }));
                 }
             })

            await getAllDivisionsBySubDepartmentID(employeeI.sub_department).then(res => {
                if (res) {
                    setDivisions(res);
                }
            })

        } else if (employeeI.role === "division_head") {
            if (!employeeI.division) return
            await getAllUsersBYDivisionID(employeeI.division).then(res => {
                if (res) {
                    setEmployees(res.filter(r => !(r.role === "department_head" || r.role === "sub_department_head" || r.role === "division_head")) );
                }
            })
        } else if(employeeI.role === "unit_head") {
            if (!employeeI.unit) return
            await getAllUsersBYUnitID(employeeI.unit).then(res => {
                if (res) {
                    setEmployees(res.filter(r => !(r.role === "department_head" || r.role === "sub_department_head" || r.role === "division_head" || r.role === "unit_head")) );
                }
            })
        } else {
            setEmployees([])
        }
    }

    // console.log(employees.filter(employee => employee.department_id === employeeI?.department || employee.rank === "Director"));

    return (
        <div className={`w-full min-h-screen h-full flex flex-col items-center bg-white py-10 gap-16 shadow-xs shadow-stone-800/60`}>
            <div className={`flex flex-row items-center justify-center`}>
                <img className={`w-28`} src={logo} alt="KMC" />
            </div>
            <div className={`w-full h-full max-h-[90vh] overflow-auto no-scrollbar`}>
                <ul className={`w-full h-fit flex flex-col gap-2 justify-evenly py-2`}>
                    <li className={`w-full h-fit py-2 ${tab === TabType.SideDashboard ? "bg-stone-100" : "bg-stone-50"} px-4 hover:font-bold ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}>
                        <p onClick={() => {
                            setTab(TabType.SideDashboard);
                            setTag(TabType.SideDashboard);
                            setTabEmployee(undefined);
                            setKind("")
                            setButtonType(undefined)
                        }} className={`w-full h-fit hover:font-bold flex flex-row gap-2 items-center`}>
                            <TfiDashboard
                                className={`text-stone-950 font-semibold w-5 h-5`}
                            />
                            Dashboard
                        </p>
                    </li>
                    <li
                        className={`w-full ${(employeeI?.role === "ceo") && (employeeI !== undefined) ? "" : "sub_display"} h-fit ${tab === TabType.SideDepartment ? "bg-stone-100" : "bg-stone-50"} py-2 px-4  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100 rounded-md`}
                    >
                        <p
                            onClick={() => {
                                setTab(TabType.SideDepartment);
                                setTag(TabType.SideDepartment);
                                setTabEmployee(undefined);
                                setKind("")
                                setButtonType(undefined)
                            }}
                            className={`w-full h-fit hover:font-bold flex flex-row gap-1 items-center`}
                        >
                            <MdDashboardCustomize className={`text-stone-400 w-5 h-5`}/>
                            Departments
                        </p>
                        <ul
                            className={`w-full ${tab === TabType.SideDepartment ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-100 mt-2 mb-2`}
                        >
                            {
                                departments.map((department, i) => (

                                    <li
                                        key={i}
                                        className={`w-full h-fit  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-200`}
                                    >
                                        <p
                                            onClick={() => {
                                                setTag(TabType.SideDepartment);
                                                setSelectedTab(`departments/${department.id}`);
                                                setTabEmployee(undefined);
                                                setKind("")
                                                setButtonType(undefined)

                                            }}
                                            className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                        >
                                            {department.name}
                                        </p>
                                    </li>
                                ))
                            }
                        </ul>
                    </li>
                    <li
                        className={`w-full ${(employeeI?.role === "department_head" || employeeI?.role === "ceo") && (employeeI !== undefined) ? "" : "sub_display"} h-fit ${tab === TabType.SideDepartment ? "bg-stone-100" : "bg-stone-50"} py-2 px-4  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100 rounded-md`}
                    >
                        <p
                            onClick={() => {
                                setTab(TabType.SideSubDepartment);
                                setTag(TabType.SideSubDepartment);
                                setTabEmployee(undefined);
                                setKind("")
                                setButtonType(undefined)
                            }}
                            className={`w-full h-fit hover:font-bold flex flex-row gap-1 items-center`}
                        >
                            <MdDashboardCustomize className={`text-stone-400 w-5 h-5`}/>
                            Sub Departments
                        </p>
                        <ul
                            className={`w-full ${tab === TabType.SideSubDepartment ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-100 mt-2 mb-2`}
                        >
                            {
                                subDepartments.map((subDepartment, i) => (

                                    <li
                                        key={i}
                                        className={`w-full h-fit  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-200`}
                                    >
                                        <p
                                            onClick={() => {
                                                setTag(TabType.SideSubDepartment);
                                                setSelectedTab(`departments/${subDepartment.id}`);
                                                setTabEmployee(undefined);
                                                setKind("")
                                                setButtonType(undefined)

                                            }}
                                            className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                        >
                                            {subDepartment.name}
                                        </p>
                                    </li>
                                ))
                            }
                        </ul>
                    </li>
                    <li
                        className={`w-full ${(employeeI?.role === "department_head" || employeeI?.role === "sub_department_head") && (employeeI !== undefined) ? "" : "sub_display"} h-fit ${tab === TabType.SideDivision ? "bg-stone-100" : "bg-stone-50"} py-2 px-4  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                    >
                        <p
                            onClick={() => {
                                setTab(TabType.SideDivision);
                                setTag(TabType.SideDivision);
                                setTabEmployee(undefined);
                                setKind("")
                                setButtonType(undefined)
                            }}
                            className={`w-full h-fit hover:font-bold flex flex-row gap-1 items-center`}
                        >
                            <MdDashboardCustomize className={`text-stone-400 w-5 h-5`}/>
                            Divisions
                        </p>
                        <ul
                            className={`w-full ${tab === TabType.SideDivision ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-100 mt-2 mb-2`}
                        >
                            {
                                divisions.map((division, i) => (

                                    <li
                                        key={i}
                                        className={`w-full h-fit  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                    >
                                        <p
                                            onClick={() => {
                                                setTag(TabType.SideDivision);
                                                setSelectedTab(`divisions/${division.id}`)
                                                setKind("")
                                                setTabEmployee(undefined)
                                                setButtonType(undefined)
                                            }}
                                            className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                        >
                                            {division.name}
                                        </p>
                                    </li>
                                ))
                            }
                        </ul>
                    </li>

                    <li
                        className={`w-full ${(employeeI?.role === "division_head") && (employeeI !== undefined) ? "" : "sub_display"} h-fit ${tab === TabType.SideDivision ? "bg-stone-100" : "bg-stone-50"} py-2 px-4  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                    >
                        <p
                            onClick={() => {
                                setTab(TabType.SideUnit);
                                setTag(TabType.SideUnit);
                                setTabEmployee(undefined);
                                setKind("")
                                setButtonType(undefined)
                            }}
                            className={`w-full h-fit hover:font-bold flex flex-row gap-1 items-center`}
                        >
                            <MdDashboardCustomize className={`text-stone-400 w-5 h-5`}/>
                            Units
                        </p>
                        <ul
                            className={`w-full ${tab === TabType.SideUnit ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-100 mt-2 mb-2`}
                        >
                            {
                                units.map((unit, i) => (
                                    (unit.division_id === employeeI?.division) ? (
                                        <li
                                            key={i}
                                            className={`w-full h-fit  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                        >
                                            <p
                                                onClick={() => {
                                                    setTag(TabType.SideUnit);
                                                    setSelectedTab(`units/${unit.id}`)
                                                    setKind("")
                                                    setTabEmployee(undefined)
                                                    setButtonType(undefined)
                                                }}
                                                className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                            >
                                                {unit.name}
                                            </p>
                                        </li>

                                    ) : ""

                                ))
                            }
                        </ul>
                    </li>

                    <li
                        className={`w-full h-fit ${tab === TabType.SideEmployee ? "bg-stone-100" : "bg-stone-50"} py-2 px-4  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100 rounded-md`}
                    >
                        <p
                            onClick={() => {
                                setTab(TabType.SideEmployee);
                                setTag(TabType.SideEmployee);
                                setTabEmployee(undefined);
                                setKind("")
                                setButtonType(undefined)
                            }}
                            className={`w-full h-fit hover:font-bold flex flex-row gap-1 items-center`}
                        >
                            <MdDashboardCustomize className={`text-stone-400 w-5 h-5`}/>
                            Employees
                        </p>
                        <ul
                            className={`w-full ${tab === TabType.SideEmployee ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}
                        >
                            {
                                employeeI?.role === "ceo" ? (
                                    employees.map((employee, i) => (
                                        (employee.department_id === employeeI.department || (employee.role === "department_head" || employee.role === "sub_department_head") || (employee.role === "division_head" && employee.department_id === 7)) ? (
                                            <li
                                                key={i}
                                                className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                            >
                                                <p
                                                    onClick={() => {
                                                        setTag(TabType.SideEmployee);
                                                        setTab(TabType.SideEmployee);
                                                        setTabEmployee(employee)
                                                        setSelectedTab(`employees/${employee.id}`)
                                                        setKind("")
                                                        setButtonType(undefined)

                                                    }}
                                                    className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                                >
                                                    {`${employee?.fname} ${employee?.mname.String} ${employee?.lname}`}
                                                </p>
                                                {/*<ul className={`w-full ${tab === TabType.SideEmployee ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>*/}

                                                {/*</ul>*/}
                                            </li>
                                        ) : ""
                                    ))
                                ) : employeeI?.role === "department_head" || employeeI?.role === "sub_department_head" || employeeI?.role === "division_head" || employeeI?.role === "unit_head" ? (
                                    employees.map((employee, i) => (

                                        <li
                                            key={i}
                                            className={`w-full h-fit ${selectedTab.split("/")[0].trim() === "employees" && selectedTab.split("/")[1] === `${employee.id}` ? "font-bold bg-stone-100 shadow-sm -translate-y-0.5" : ""}  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                        >
                                            <p
                                                onClick={() => {
                                                    setTag(TabType.SideEmployee);
                                                    setTab(TabType.SideEmployee);
                                                    setTabEmployee(employee)
                                                    setSelectedTab(`employees/${employee.id}`)
                                                    setKind("")
                                                    setButtonType(undefined)

                                                }}
                                                className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                            >
                                                {`${employee?.fname} ${employee?.mname.String} ${employee?.lname}`}
                                            </p>
                                            {/*<ul className={`w-full ${tab === TabType.SideEmployee ? "flex" : "sub_display"} flex-col gap-2 justify-evenly bg-stone-200 mt-2 mb-2`}>*/}

                                            {/*</ul>*/}
                                        </li>
                                    ))
                                ) : (
                                    <li
                                        className={`w-full h-fit  py-2 px-4 hover:font-bold  ease-in-out hover:shadow-sm hover:-translate-y-0.5 cursor-pointer hover:bg-stone-100`}
                                    >
                                        <p
                                            onClick={() => {
                                                setTag(TabType.SideEmployee);
                                                setSelectedTab(`employees/${employeeI?.user_id}`)
                                                setTabEmployee(undefined)
                                                setKind("")
                                                setButtonType(undefined)
                                            }}
                                            className={`w-full h-fit hover:font-bold flex flex-row pl-4 items-center`}
                                        >
                                            {`${employeeI?.fname} ${employeeI?.mname} ${employeeI?.lname}`}
                                        </p>
                                    </li>
                                )
                            }
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default SideBar;