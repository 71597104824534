import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";

export type ActivityData = {
    id: number;
    name: string;
    is_complete: boolean;
    dead_line: string;
    objective_id: number;
    employee_id: {
        Int64: number,
        Valid: boolean,
    }
    created_at: string;
};

export type ActivityInput = {
    name: string;
    is_complete?: boolean;
    employee_id?: number;
    dead_line: Date;
    objective_id: number;
};

export const getActivities = async () => {
    try {
        return await axios
            .get<ActivityData[]>(API_URL + "activity", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const createActivityDB = async (input: ActivityInput) => {
    try {
        return await axios
            .post<ActivityData>(API_URL + "activity", input,{
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};
