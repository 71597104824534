import {
    createContext,
    FC,
    ReactNode,
    useContext,
    useState,
} from "react";
import {EmployeeDB} from "../services/employee";

// Define types for the authentication context
interface TabEmployeeType {
    tabEmployee: EmployeeDB|undefined;
    setTabEmployee: (emp: EmployeeDB|undefined) => void;
}

const TabEmployee = createContext<TabEmployeeType | undefined>(undefined);

const TabEmployeeProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [employee, setEmployee] = useState<EmployeeDB|undefined>(
        undefined
    );

    const setEmp = (emp:EmployeeDB|undefined) => {
        setEmployee(emp)
    }


    // Provide the authentication context to the children components
    return (
        <TabEmployee.Provider
            value={{
                tabEmployee: employee,
                setTabEmployee: setEmp
            }}
        >
            {children}
        </TabEmployee.Provider>
    );
};

export const useTabEmployee = (): TabEmployeeType => {
    const context = useContext(TabEmployee);
    if (!context) {
        throw new Error("useTabEmployee must be used within an TabEmployeeProvider");
    }
    return context;
};

export default TabEmployeeProvider;
