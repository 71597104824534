import React, {
	createContext,
	useContext,
	Dispatch,
	SetStateAction,
} from "react";
import { TabType } from "../../utils/mainTab/tabType";

export const TabContext = createContext<
	{ tab: TabType; setTab: Dispatch<SetStateAction<TabType>> } | undefined
>(undefined);

export const useTabContext = () => {
	const tab = useContext(TabContext);
	if (tab === undefined) {
		throw new Error("Tab should be used on main dashboard");
	}

	return tab;
};
