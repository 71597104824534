import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {TaskData} from "./taskService";
import {EmployeeDB} from "./employee";
import {Department} from "./departmentService";
import {Unit} from "./unitService";
import {Division} from "./divisionService";


export type WorkPlanComment = {
    id: number;
    comment: string;
    work_plan_id: number;
    supervisor_id: number;
    is_cleared: boolean;
    created_at: Date;
    Supervisor: EmployeeDB;
};

export type WorkPlanData = {
    id: number;
    work_plan_number: string;
    month: string;
    year: number;
    division_id: {
        Int64: number;
        Valid: boolean
    };
    unit_id: {
        Int64: number;
        Valid: boolean
    };
    department_id: number;
    sub_department_id: {
        Int64: number;
        Valid: boolean
    };
    is_complete: boolean;
    employee_id: number;
    manager_approval: string;
    unit_head_approval: string;
    sm_approval: string;
    director_approval: string;
    ceo_approval: string;
    is_report_generated: boolean;
    file: string;
    Employee: EmployeeDB;
    Director: EmployeeDB;
    UnitHead: EmployeeDB;
    Manager: EmployeeDB;
    SM: EmployeeDB;
    Ceo: EmployeeDB;
    manager_approval_at: {
        Time: string;
        Valid: boolean;
    };
    unit_head_approval_at: {
        Time: string;
        Valid: boolean;
    };
    sm_approved_at: {
        Time: string;
        Valid: boolean;
    };
    director_approved_at: {
        Time: string;
        Valid: boolean;
    };
    ceo_approved_at: {
        Time: string;
        Valid: boolean;
    };
    Tasks : TaskData[];
    Comments : WorkPlanComment[];
    Department: Department;
    Unit: Unit;
    Division: Division;
    dead_line: string;
    created_at: string;
};

export type WorkPlanInput = {
    month: string;
    year: number;
    type: string;
    division_id?: number;
    sub_department_id?: number;
    unit_id?: number;
    department_id: number;
    employee_id: number;
}

export type WorkPlanEditInputs = {
    month: string;
    year: number;
    type: string;
    division_id?: number;
    sub_department_id?: number;
    unit_id?: number;
    department_id: number;
    // employee_id: number;
}

export type WorkPlanApprovalInput = {
    status: string;
    approving_officer_id: number;
    comment: string;
}



export const createWorkPlan = async (workPlan: WorkPlanInput) => {
    try {
        return await axios
            .post<number>(API_URL + `workPlan`, workPlan, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
}

export const getWorkPlans = async () => {
    try {
        return await axios
            .get<WorkPlanData[]>(API_URL + "workPlan", {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {}
};


export const getWorkPlanByEmployeeID = async (param: number) => {
    try {
        return await axios
            .get<WorkPlanData[]>(API_URL + `employee/${param}/workPlans`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};


export const getAllWorkPlansByDivision = async (param: number, type: string) => {
    try {
        return await axios
            .get<WorkPlanData[]>(API_URL + `divisionType/${param}/workPlans?type=${type}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};

export const getAllWorkPlansByUnit = async (param: number, type: string) => {
    try {
        return await axios
            .get<WorkPlanData[]>(API_URL + `unitType/${param}/workPlans?type=${type}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};


export const editWorkPlan = async (param: number, workPlan: WorkPlanEditInputs) => {
    try {
        return await axios
            .put<number>(API_URL + `workPlan/${param}`, workPlan, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
}


export const getAllWorkPlansByDepartment = async (param: number, type: string) => {
    try {
        return await axios
            .get<WorkPlanData[]>(API_URL + `departmentType/${param}/workPlans?type=${type}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};

export const getAllWorkPlansBySubDepartment = async (param: number, type: string) => {
    try {
        return await axios
            .get<WorkPlanData[]>(API_URL + `subDepartmentType/${param}/workPlans?type=${type}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err)
    }
};


export const getWorkPlan = async (param: number) => {
    try {
        return await axios
            .get<WorkPlanData>(API_URL + `workPlan/${param}`, {
                headers: {
                    "X-API-KEY": X_API_KEY
                }
            })
            .then(res => res.data);
    } catch (err) {
        console.log(err);
    }
};

export const managerWorkPlanApproval = async (param: number, inputs: WorkPlanApprovalInput) => {
    try {
        return await axios.put(API_URL + `workPlan/maApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const unitWorkPlanApproval = async (param: number, inputs: WorkPlanApprovalInput) => {
    try {
        return await axios.put(API_URL + `workPlan/unitApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const smWorkPlanApproval = async (param: number, inputs: WorkPlanApprovalInput) => {
    try {
        return await axios.put(API_URL + `workPlan/smApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}


export const directorWorkPlanApproval = async (param: number, inputs: WorkPlanApprovalInput) => {
    try {
        return await axios.put(API_URL + `workPlan/directorApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}


export const ceoWorkPlanApproval = async (param: number, inputs: WorkPlanApprovalInput) => {
    try {
        return await axios.put(API_URL + `workPlan/ceoApproval/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}



export type TableDataFormat = {
    index: string;
    objective_name: string;
    activity_name:  string;
    target:        string;
    unit_of_measure: string;
    resource:      string;
    dead_line:      string;
}

export type TableEmployee = {
    name:     string;
    position: string;
    signature: string;
    date:     string;
}

export type Header = {
    owner:      string;
    department: string;
    for:        string;
}

export type TableFooter = {
    prepared: TableEmployee;
    reviewed?: TableEmployee;
    approved: TableEmployee;
}

export type WorkPlanPdfGen = {
    header: Header;
    data:   TableDataFormat[];
    footer: TableFooter;
}



export const generateWorkPlanPdf = async (param: number, inputs: WorkPlanPdfGen) => {
    try {
        return await axios.put<string>(API_URL + `generate/workPlan/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const generateWorkPlan2Pdf = async (param: number, inputs: WorkPlanPdfGen) => {
    try {
        return await axios.put<string>(API_URL + `generate2/workPlan/${param}`, inputs, {
            headers: {
                "X-API-KEY": X_API_KEY
            }
        });
    } catch (err) {
        console.log(err);
    }
}