import React, {FC, useEffect} from 'react';
import {
    ceoWorkPlanApproval,
    directorWorkPlanApproval,
    getWorkPlanByEmployeeID,
    managerWorkPlanApproval,
    smWorkPlanApproval, unitWorkPlanApproval,
    WorkPlanData
} from "../../services/workPlanServiice";
import {useAuth} from "../../context/authContext";
import {useNavigate} from "react-router-dom";
import {useButtonTypeContext} from "../../context/button/buttonContext";
import {IoPersonCircle} from "react-icons/io5";
import {ButtonType} from "../../context/button/buttonTypes";
import {workPlanReStruct} from "../../utils/workPlanRestruct";
import {EmployeeDB} from "../../services/employee";
import {
    ceoReportApproval,
    directorReportApproval,
    getReportByEmployeeID, managerReportApproval,
    ReportData,
    smReportApproval
} from "../../services/reportService";
import {ObjectiveRTR, reportTaskReStructure} from "../../utils/reportTaskReStructure";
import {scoreFilter, statusScoreMatch} from "../../utils/scoreUtil";
import {createTaskScore} from "../../services/taskScoreService";
import {
    months,
    MonthStructure,
    ScoresBarGraphStructure,
    scoresGraphReStructure,
    yearsExtractor
} from "../../utils/scoresGraphReStructure";
import {FcApproval, FcCancel} from "react-icons/fc";
import {PiDotsThree} from "react-icons/pi";


const ll = [1,2,3,4,5,6,7,8,9,10,11,12]

const EmployeeSummary:FC<{employee: EmployeeDB}> = ({employee}) => {
    const [refresh, setRefresh] = React.useState(false);
    const [workPlans, setWorkPlans] = React.useState<WorkPlanData[]>([]);
    const [selectedWorkPlan, setSelectedWorkPlan] = React.useState<WorkPlanData|undefined>(undefined);

    const [reports, setReports] = React.useState<ReportData[]>([]);
    const [selectedReport, setSelectedReport] = React.useState<ReportData|undefined>(undefined);
    const [reportRTR, setReportRTR] = React.useState<ObjectiveRTR[]>([]);

    const [graphYears, setGraphYears] = React.useState<number[]>([]);
    const [graphReportYearData, setGraphReportYearData] = React.useState<ScoresBarGraphStructure[]>([]);
    const [selectedGraphYears, setSelectedGraphYears] = React.useState<number|undefined>(undefined);
    const [seelectedGraphYearData, setSeelectedGraphYearData] = React.useState<MonthStructure[]>([]);

    const [approvalComment, setApprovalComment] = React.useState<string>("");
    const [approvalCommentError, setApprovalCommentError] = React.useState<string|undefined>(undefined);
    const [loading, setLoading] = React.useState(false);


    const {employeeI} = useAuth();

    const navigate = useNavigate();


    useEffect(() => {
        if (!employeeI) navigate("/login")
        fetchData().then();
    },[employee, refresh]);

    const handleChangeWorkPlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const workPlan = workPlans.find((workPlan) => workPlan.id === value);
        if (!workPlan) return;
        setSelectedWorkPlan(workPlan);
    }

    const handleChangeGraphYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        // console.log(value)
        setSelectedGraphYears(value);
        // console.log(graphReportYearData)
        let dd = graphReportYearData.find((year) => year.year === value);
        if (!dd) return;
        // console.log(dd)
        setSeelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex))
    }

    const handleChangeReport = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const report = reports.find((report) => report.id === value);
        if (!report) return;
        setSelectedReport(report);
        setReportRTR(reportTaskReStructure(report.WorkPlan.Tasks));
    }

    const handleWorkPlanApproval = async (e: React.MouseEvent<HTMLButtonElement>, status: string) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        if (!employeeI) {
            navigate("/login");
            return;
        }
        if (!selectedWorkPlan) {
            setLoading(false);
            return
        };
        if (approvalComment.length <= 0) {
            setApprovalCommentError("Please comment on your decision!");
            setLoading(false);
            return;
        }
        const input = {
            approving_officer_id: employeeI.user_id,
            comment: approvalComment,
            status: status,
        }
        try {
            if (employeeI.role === "unit_head") {
                const ff = await unitWorkPlanApproval(selectedWorkPlan?.id, input);
                console.log(ff);
                setRefresh(!refresh);
                return;
            } else if (employeeI.role === "division_head") {
                const ff = await managerWorkPlanApproval(selectedWorkPlan?.id, input);
                console.log(ff);
                setRefresh(!refresh);
                return;
            } else if (employeeI.role === "sub_department_head") {
                const ff = await smWorkPlanApproval(selectedWorkPlan?.id, input);
                console.log(ff);
                setRefresh(!refresh);
                return;
            } else if (employeeI.role === "department_head") {
                const ff = await directorWorkPlanApproval(selectedWorkPlan?.id, input);
                console.log(ff);
                setRefresh(!refresh);
                return;
            } else if (employeeI.role === "ceo") {
                const ff = await ceoWorkPlanApproval(selectedWorkPlan?.id, input);
                console.log(ff);
                setRefresh(!refresh);
                return;
            } else {
                return;
            }

        } catch (e) {
            console.error(e);
        }
    }


    const handleReportApproval = async (e: React.MouseEvent<HTMLButtonElement>, status: string) => {
        e.preventDefault();
        if (!employeeI) {
            navigate("/login");
            return;
        }
        if (!selectedReport) return;
        const input = {
            approving_officer_id: employeeI.user_id,
            status: status,
        }
        try {
            if (employeeI.role === "unit_head") {
                reportRTR.forEach((obj) => {
                    obj.activities.forEach((act) => {
                        act.tasks.forEach( async (task) => {
                            await createTaskScore({
                                name: task.taskName,
                                comment: task.taskScore.comment,
                                report_id: selectedReport.id,
                                score: task.taskScore.score,
                                status: task.taskScore.status,
                                task_id: task.taskId,
                                supervisor_id: employeeI.user_id,
                            });
                        })
                    })
                })
                const ff = await managerReportApproval(selectedReport.id, input);
                console.log(ff);
                setRefresh(!refresh);
                return;
            } else if (employeeI.role === "division_head") {
                if (employee.role === "unit_head") {
                    reportRTR.forEach((obj) => {
                        obj.activities.forEach((act) => {
                            act.tasks.forEach( async (task) => {
                                await createTaskScore({
                                    name: task.taskName,
                                    comment: task.taskScore.comment,
                                    report_id: selectedReport.id,
                                    score: task.taskScore.score,
                                    status: task.taskScore.status,
                                    task_id: task.taskId,
                                    supervisor_id: employeeI.user_id,
                                });
                            })
                        })
                    })

                    const ff = await managerReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                    return;
                } else if (employee.role === "sub_department_head" || employee.role === "department_head" || employee.role === "ceo") {
                    return;
                } else {

                    const ff = await smReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                }

                return;
            } else if (employeeI.role === "sub_department_head") {
                if (employee.role === "division_head") {
                    reportRTR.forEach((obj) => {
                        obj.activities.forEach((act) => {
                            act.tasks.forEach( async (task) => {
                                await createTaskScore({
                                    name: task.taskName,
                                    comment: task.taskScore.comment,
                                    report_id: selectedReport.id,
                                    score: task.taskScore.score,
                                    status: task.taskScore.status,
                                    task_id: task.taskId,
                                    supervisor_id: employeeI.user_id,
                                });
                            })
                        })
                    })
                    const ff = await smReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                    return;
                } else if (employee.role === "sub_department_head" || employee.role === "department_head" || employee.role === "ceo") {
                    return;
                } else {

                    const ff = await smReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                }
                return;
            } else if (employeeI.role === "department_head") {

                if (employee.role === "sub_department_head") {
                    reportRTR.forEach((obj) => {
                        obj.activities.forEach((act) => {
                            act.tasks.forEach( async (task) => {
                                await createTaskScore({
                                    name: task.taskName,
                                    comment: task.taskScore.comment,
                                    report_id: selectedReport.id,
                                    score: task.taskScore.score,
                                    status: task.taskScore.status,
                                    task_id: task.taskId,
                                    supervisor_id: employeeI.user_id,
                                });
                            })
                        })
                    })
                    const ff = await directorReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                    return;
                } else if (employee.role === "department_head" || employee.role === "ceo") {
                    return;
                } else {

                    const ff = await directorReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                }
                return;
            } else if (employeeI.role === "ceo") {

                if (employee.role === "department_head" || (employee.department_id === employeeI.department && employee.role === "sub_department_head")) {
                    reportRTR.forEach((obj) => {
                        obj.activities.forEach((act) => {
                            act.tasks.forEach( async (task) => {
                                await createTaskScore({
                                    name: task.taskName,
                                    comment: task.taskScore.comment,
                                    report_id: selectedReport.id,
                                    score: task.taskScore.score,
                                    status: task.taskScore.status,
                                    task_id: task.taskId,
                                    supervisor_id: employeeI.user_id,
                                });
                            })
                        })
                    })
                    const ff = await ceoReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                    return;
                } else {
                    const ff = await ceoReportApproval(selectedReport.id, input);
                    console.log(ff);
                    setRefresh(!refresh);
                    return;
                }

                // return;
            } else {
                return;
            }

        } catch (e) {
            console.error(e);
        }
    }


    const fetchData = async () => {
        if (!employeeI) {
            navigate("/login");
            return;
        }
        await getWorkPlanByEmployeeID(employee.id).then(res => {
            if (res) {
                setWorkPlans(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedWorkPlan(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );
            }
        });
        await getReportByEmployeeID(employee.id).then(res => {
            if (res) {
                setReports(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedReport(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );

                if (res.length > 0) {
                    setReportRTR(reportTaskReStructure(res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0].WorkPlan.Tasks));
                    setGraphReportYearData(scoresGraphReStructure(res));
                    setGraphYears(yearsExtractor(res));
                    let yy = yearsExtractor(res);
                    let mm = scoresGraphReStructure(res);
                    let dd = mm.find(m => m.year === yy[0]);
                    if (!dd) {
                        setSeelectedGraphYearData([]);
                        return;
                    }
                    setSeelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex));

                } else {
                    setReportRTR([]);
                    setGraphReportYearData([]);
                    setGraphYears([]);
                    setSeelectedGraphYearData([]);
                }

                // setReportRTR(reportTaskReStructure(res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0].WorkPlan.Tasks))
                // setGraphReportYearData(scoresGraphReStructure(res));
                // setGraphYears(yearsExtractor(res));
                // let yy = yearsExtractor(res);
                // let mm = scoresGraphReStructure(res);
                // let dd = mm.find(m => m.year === yy[0]);
                // if (!dd) {
                //     setSeelectedGraphYearData([]);
                //     return;
                // }
                // setSeelectedGraphYearData(dd.monthlyData.sort((a,b) => a.monthIndex - b.monthIndex))
            }
        });
    }


    const handleChangeTaskStatus = (e: React.ChangeEvent<HTMLSelectElement>, objIndex: number, actIndex: number, taskIndex: number) => {
        let objectives = [...reportRTR];
        let objective = objectives[objIndex];
        let activities = [...objective.activities];
        let activity = activities[actIndex];
        let tasks = [...activity.tasks];
        let task = tasks[taskIndex];
        task.taskScore.status = e.target.value;
        task.taskScore.score = statusScoreMatch(e.target.value);
        tasks.splice(taskIndex, 1);
        tasks.splice(taskIndex, 0, task);
        activity.tasks = tasks;
        activities.splice(actIndex, 1);
        activities.splice(actIndex, 0, activity);
        objective.activities=activities;
        // objectives.splice(objIndex, 1);
        // objectives.splice(actIndex, 0, objective);
        setReportRTR(objectives);
    }

    const handleChangeTaskComment = (e: React.ChangeEvent<HTMLInputElement>, objIndex: number, actIndex: number, taskIndex: number) => {
        let objectives = [...reportRTR];
        let objective = objectives[objIndex];
        let activities = [...objective.activities];
        let activity = activities[actIndex];
        let tasks = [...activity.tasks];
        let task = tasks[taskIndex];
        task.taskScore.comment = e.target.value;
        tasks.splice(taskIndex, 1);
        tasks.splice(taskIndex, 0, task);
        activity.tasks = tasks;
        activities.splice(actIndex, 1);
        activities.splice(actIndex, 0, activity);
        objective.activities=activities;
        // objectives.splice(objIndex, 1);
        // objectives.splice(actIndex, 0, objective);
        setReportRTR(objectives);
    }


    // console.log(seelectedGraphYearData)

    const {setButtonType, setKind} = useButtonTypeContext()
    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 py-32`}>
            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <p>{`${employee.fname} ${employee.mname.String} ${employee.lname}`}</p>
                        <p>{employee.position}</p>
                        {/*<p>Performance Avg: 4.5</p>*/}
                    </div>
                </div>
                <div className={`w-fit h-fit`}>
                    <p className={`text-[#1c1c1c] text-2xl font-semibold`}>Performance<br/>Summary</p>
                </div>
            </div>
            <div className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col justify-between gap-6`}>
                <div className={`w-full h-fit flex flex-row items-center justify-between`}>
                    <p className={`font-medium text-lg text-[#1c1c1c]`}>Overview</p>
                    {
                        graphYears.length > 0 && (
                            <select
                                onChange={handleChangeGraphYear}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                {
                                    graphYears.map(year => (
                                        <option value={year} key={year}>{year}</option>
                                    ))
                                }
                            </select>

                        )
                    }

                </div>
                <div className={`w-full h-64 flex flex-row justify-start items-end`}>
                    {
                        seelectedGraphYearData.length > 0 ? ll.map((n, i) => {
                            let kk = seelectedGraphYearData.find(gf => gf.monthIndex === n)

                            return (
                                <div key={i} className={`w-1/2 h-fit flex flex-col gap-1 items-center`}>
                                    {

                                        kk ? (<div
                                                className={`w-full ${kk.value < 0.5 ? "h-5 bg-[#ef5350]" : kk.value >= 0.5 && kk.value < 1.5 ? "h-20 bg-[#fcf3cf]" : kk.value >= 1.5 && kk.value < 2.5 ? "h-32 bg-[#F8E7A5]" : kk.value >= 2.5 && kk.value < 3.5 ? "h-40 bg-[#8DEB8D]" : "h-56 bg-[#65C365]"}`}></div>
                                        ) : ""
                                    }

                                    <p>{months[i].slice(0, 3)}</p>
                                </div>
                            )
                        }) : (
                            <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No scores to
                                    report on yet!</p>
                            </div>
                        )
                    }

                </div>
            </div>

            {/*Report*/}
            <div className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                    <select
                        onChange={handleChangeReport}
                        value={selectedReport?.id}
                        className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                        {
                            reports.map((reports, i) => (
                                <option key={i}
                                        value={reports.id}>{`${reports.month.slice(0, 3)} - ${reports.year} Report`}</option>
                            ))
                        }
                    </select>
                    <div className={`w-fit h-fit flex flex-row justify-evenly items-center gap-2`}>
                        <p onClick={() => {
                            setKind("individual")
                            setButtonType(ButtonType.Report)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Reports</p>
                        <p onClick={() => {
                            setKind("division")
                            setButtonType(ButtonType.Report)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Div Reports</p>
                        <p onClick={() => {
                            setKind("department")
                            setButtonType(ButtonType.Report)
                        }}
                           className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View
                            Dep Reports</p>
                    </div>
                </div>

                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>

                    {
                        (selectedReport && reportRTR.length > 0) ? (
                            <>
                                {
                                    reportRTR.map((obj, index) => (

                                        obj.activities.length > 0 ? (
                                            <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                                                    <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                                </div>
                                                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                                    <div
                                                        className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2 overflow-x-scroll scrollbar-thumb-rounded-full scrollbar-thin touch-auto scrollbar-thumb-stone-200 scrollbar-track-transparent scroll-pr-4`}>
                                                        <div
                                                            className={`w-[2300px] grid grid-cols-12 text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle gap-x-4`}>
                                                            <div className={`w-full h-fit col-span-2`}>Activity</div>
                                                            <div className={`w-full h-fit col-span-2`}>Target</div>
                                                            <div className={`w-full h-fit col-span-2`}>Status</div>
                                                            <div className={`w-full h-fit col-span-1`}>%Age Progress</div>
                                                            <div className={`w-full h-fit col-span-2`}>Supervisor's Comments/Remarks</div>
                                                            <div className={`w-full h-fit col-span-2`}>Status</div>
                                                            <div className={`w-full h-fit col-span-1`}>Score</div>
                                                        </div>
                                                        <div className={`w-[2300px] bg-[#999999] py-[1px]`}></div>
                                                        {
                                                            obj.activities.length >= 0 && (
                                                                obj.activities.map((activity, i) => (
                                                                    <div key={i}
                                                                         className={`w-[2300px] h-fit flex flex-col gap-4 justify-center relative`}>
                                                                        <div
                                                                            className={`w-full text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-12 gap-x-6`}>
                                                                            <div
                                                                                className={`w-full col-span-2 h-fit flex flex-row gap-1 items-center`}>
                                                                                <p>{activity.activityName}</p>
                                                                            </div>
                                                                            {
                                                                                activity.tasks.length <= 0 ?
                                                                                    (
                                                                                        <div
                                                                                            className={`w-full col-span-10 h-fit flex flex-row items-center justify-center`}>
                                                                                            <div className={`w-fit h-fit`}>
                                                                                                <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                                    targets allocated for this
                                                                                                    Activity.</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) :
                                                                                    (
                                                                                        <div
                                                                                            className={`w-full col-span-10 h-fit flex flex-col gap-4`}>
                                                                                            {
                                                                                                activity.tasks.map((task, j) => (
                                                                                                    task.onReport && (

                                                                                                        <div key={j}
                                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-10 gap-x-6`}>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-row gap-1 items-center`}>
                                                                                                                {task.taskName}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                                <p className={``}>{task.status}</p>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2`}>
                                                                                                                <p className={``}>{task.cumulative}</p>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                                <input
                                                                                                                    disabled={
                                                                                                                        (employeeI?.user_id === employee.id) ||
                                                                                                                        (employee.role !== "unit_head" && employeeI?.role === "division_head") ||
                                                                                                                        (employee.role !== "division_head" && employeeI?.role === "sub_department_head") ||
                                                                                                                        (employee.role !== "sub_department_head" && employeeI?.role === "department_head") ||
                                                                                                                        (employee.role !== "department_head" && employeeI?.role === "ceo")
                                                                                                                    }
                                                                                                                    required={true}
                                                                                                                    type={"text"}
                                                                                                                    onChange={(e) => handleChangeTaskComment(e, index, i, j)}
                                                                                                                    value={task.taskScore.comment}
                                                                                                                    className={`w-full text-center py-1.5 px-2 bg-stone-50 outline-0 focus:outline-none focus:ring-0`}/>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                                <select
                                                                                                                    disabled={
                                                                                                                        (employeeI?.user_id === employee.id) ||
                                                                                                                        (employee.role !== "unit_head" && employeeI?.role === "division_head") ||
                                                                                                                        (employee.role !== "division_head" && employeeI?.role === "sub_department_head") ||
                                                                                                                        (employee.role !== "sub_department_head" && employeeI?.role === "department_head") ||
                                                                                                                        (employee.role !== "department_head" && employeeI?.role === "ceo")
                                                                                                                    }
                                                                                                                    onChange={(e) => handleChangeTaskStatus(e, index, i, j)}
                                                                                                                    value={task.taskScore.status}
                                                                                                                    className={`w-full px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                                                                                                            <option value={"not_applicable"}>Not Applicable</option>
                                                                                                                            <option value={"committee"}>Presented to and approved by the relevant committees [100% Complete]</option>
                                                                                                                            <option value={"head"}>Presented to and approved by the Head of Department [75% Complete]</option>
                                                                                                                            <option value={"draft"}>Presented in draft format, no review has been undertaken [50% Complete]</option>
                                                                                                                            <option value={"infancy"}>Key deliverables still in infancy stage [Outline Format- 25% Complete]</option>
                                                                                                                            <option value={"no_evidence"}>No evidence of Work on the Key Deliverables [0%]</option>
                                                                                                                            <option value={"delayed"}>Activity Delayed</option>
                                                                                                                </select>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`w-full h-fit col-span-1 flex flex-col gap-2`}>
                                                                                                            <span
                                                                                                                className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(task.taskScore.score)} font-semibold`}>
                                                                                                                {task.taskScore.id <= 0 && task.taskScore.status.length <= 0 ? "" : task.taskScore.score}
                                                                                                            </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )

                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                            }
                                                                        </div>

                                                                        <div
                                                                            className={`w-full flex flex-row items-center`}>
                                                                            <div
                                                                                className={`w-[2300px] h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                        </div>


                                                                    </div>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ("")


                                    ))
                                }


                                {
                                    employee.id === employeeI?.user_id ? (
                                        <div>
                                            Hi manager
                                        </div>
                                    ) : selectedReport.manager_approval === "pending" && employeeI?.role === "division_head"  ?
                                        (
                                            <div className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                <button onClick={(e) => handleReportApproval(e, "approved")}
                                                        className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Approve</button>
                                                <button onClick={(e) => handleReportApproval(e, "rejected")}
                                                        className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Reject</button>
                                            </div>
                                        ) : selectedReport.manager_approval === "pending" && employeeI?.role === "division_head"  ?
                                        (
                                            <div className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                <button onClick={(e) => handleReportApproval(e, "approved")}
                                                        className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Approve</button>
                                                <button onClick={(e) => handleReportApproval(e, "rejected")}
                                                        className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Reject</button>
                                            </div>
                                        ) : selectedReport.sm_approval === "pending" && employeeI?.role === "sub_department_head"  ?
                                            (
                                                <div
                                                    className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                    <button onClick={(e) => handleReportApproval(e, "approved")}
                                                            className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Approve
                                                    </button>
                                                    <button onClick={(e) => handleReportApproval(e, "rejected")}
                                                            className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Reject
                                                    </button>
                                                </div>
                                            ) : selectedReport.director_approval === "pending" && employeeI?.role === "department_head" ?
                                                (
                                                    <div
                                                        className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                        <button onClick={(e) => handleReportApproval(e, "approved")}
                                                                className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Approve
                                                        </button>
                                                        <button onClick={(e) => handleReportApproval(e, "rejected")}
                                                                className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Reject
                                                        </button>
                                                    </div>
                                                ) : selectedReport.ceo_approval === "pending" && employeeI?.role === "ceo" && ((selectedReport.Employee.role === "division_head") || (selectedReport.Employee.role === "sub_department_head") || (selectedReport.Employee.role === "department_head") || (selectedReport.Employee.department_id === 6)) ?
                                                    (
                                                        <div
                                                            className={`w-full h-fit flex flex-row gap-4 justify-end items-center`}>
                                                            <div
                                                                className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                                <button
                                                                    onClick={(e) => handleReportApproval(e, "approved")}
                                                                    className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Approve
                                                                </button>
                                                                <button
                                                                    onClick={(e) => handleReportApproval(e, "rejected")}
                                                                    className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Reject
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (

                                                        <div
                                                            className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                                                            <div
                                                                className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                                <div
                                                                    className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid grid-cols-3 gap-x-6`}>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 text-center`}>Prepared
                                                                        By
                                                                    </div>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 text-center`}>Scored
                                                                        By
                                                                    </div>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 text-center`}>Approved
                                                                        By
                                                                    </div>
                                                                </div>
                                                                <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                                                <div
                                                                    className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-3 gap-x-6`}>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                        <p className={`w-full text-center`}>{`${selectedReport.Employee.fname} ${selectedReport.Employee.mname.String} ${selectedReport.Employee.lname}`}</p>
                                                                        <p className={`w-full text-center`}>{`${selectedReport.Employee.position}`}</p>
                                                                    </div>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                        <p className={`w-full text-center`}>{`${selectedReport.Manager.fname} ${selectedReport.Manager.mname.String} ${selectedReport.Manager.lname}`}</p>
                                                                        <p className={`w-full text-center`}>{`${selectedReport.Manager.position}`}</p>
                                                                    </div>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                        <p className={`w-full text-center`}>{`${selectedReport.SM.fname} ${selectedReport.SM.mname.String} ${selectedReport.SM.lname}`}</p>
                                                                        <p className={`w-full text-center`}>{`${selectedReport.SM.position}`}</p>
                                                                    </div>

                                                                </div>

                                                                <div
                                                                    className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-3 gap-x-6`}>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                        <p className={`w-full text-center`}>{new Date(selectedReport.created_at).toLocaleDateString("en-GB")}</p>
                                                                    </div>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                        {
                                                                            selectedReport.manager_approval_at.Valid && (
                                                                                <p className={`w-full text-center`}>{new Date(selectedReport.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                            )}
                                                                    </div>
                                                                    <div
                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                        {
                                                                            selectedReport.sm_approved_at.Valid && (

                                                                                <p className={`w-full text-center`}>{new Date(selectedReport.sm_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                }


                            </>

                        ) : (
                            <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No report generated on yet!</p>
                            </div>
                        )
                    }
                </div>

            </div>

            {/*Work Plan*/}
            <div className={`w-full h-fit flex flex-col gap-4  mt-10`}>
                <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                    {
                        workPlans.length > 0 ? (

                            <select
                                onChange={handleChangeWorkPlan}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                {
                                    workPlans.map((workPlan: WorkPlanData, i) => (
                                        <option key={i} value={workPlan.id}>{`${workPlan.month.slice(0,3)} - ${workPlan.year} Work Plan`}</option>
                                    ))
                                }
                            </select>
                        ) : <div></div>
                    }
                    <div className={`w-fit h-fit flex flex-row  justify-evenly items-center gap-2`}>
                        <p onClick={() => {
                            setKind("individual")
                            setButtonType(ButtonType.WorkPlan)
                        }} className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View WorkPlans</p>
                        <p onClick={() => {
                            setKind("division")
                            setButtonType(ButtonType.WorkPlan)
                        }} className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View Div WorkPlans</p>
                        <p onClick={() => {
                            setKind("department")
                            setButtonType(ButtonType.WorkPlan)
                        }} className={`w-fit h-fit px-2 py-1.5 bg-transparent cursor-pointer text-sm font-semibold text-[#1c1c1c] rounded-md hover:bg-white`}>View Dep WorkPlans</p>
                    </div>
                </div>
                <div className={`w-full h-fit flex flex-col justify-between gap-4`}>

                    {
                        (selectedWorkPlan && workPlanReStruct(selectedWorkPlan.Tasks).length > 0) ? (
                            <>
                                {
                                    workPlanReStruct(selectedWorkPlan.Tasks).map((obj, index) => (

                                        <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                            {/*<div className={`w-full h-fit flex flex-row justify-between items-center`}>*/}
                                            {/*    <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>*/}
                                            {/*</div>*/}
                                            <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                                <div
                                                    className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                    <div
                                                        className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                        <div className={`w-1/4 h-fit`}>{obj.objectiveName}</div>
                                                        <div
                                                            className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                            <div className={`w-full h-fit col-span-3`}>Target</div>
                                                            <div className={`w-full h-fit col-span-2`}>Unit of Measure</div>
                                                            <div className={`w-full h-fit col-span-2 text-center`}>Resource
                                                            </div>
                                                            <div className={`w-full h-fit col-span-2 text-center`}>Timeline
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                                    {
                                                        obj.activities.length >= 0 && (
                                                            obj.activities.map((activity, i) => (
                                                                <div key={i}
                                                                     className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                                    <div
                                                                        className={`text-xs lg:text-sm 2xl:text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                        <div
                                                                            className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                            <p>{activity.activityName}</p>
                                                                        </div>
                                                                        {
                                                                            activity.tasks.length <= 0 ?
                                                                                (
                                                                                    <div
                                                                                        className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                        <div className={`w-fit h-fit`}>
                                                                                            <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                                targets a located for this
                                                                                                Activity.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                ) :
                                                                                (
                                                                                    <div
                                                                                        className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                        {
                                                                                            activity.tasks.map((task, i) => (

                                                                                                <div key={i}
                                                                                                     className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                        {task.name}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                        <p className={`w-full text-center`}>{task.unit_of_measure}</p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                        <p className={`w-full text-center`}>{task.resource}</p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                        <p className={`w-full text-center`}>{new Date(task.dead_line).toLocaleDateString("en-GB")}</p>
                                                                                                        <div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </div>

                                                                    <div
                                                                        className={`w-full flex flex-row items-center`}>
                                                                        <div
                                                                            className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                    </div>


                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                {
                                    employee.id === employeeI?.user_id ? (
                                        <div>
                                            Hi manager
                                        </div>
                                    ) : selectedWorkPlan.unit_head_approval === "pending" && employeeI?.role === "unit_head"  ?
                                        (
                                            <div className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                    <label htmlFor="comment"
                                                           className="block text-sm/6 font-medium text-gray-900">
                                                        Comment
                                                    </label>
                                                    <div className="flex flex-col gap-0.5">
                                                        {approvalCommentError && (
                                                            <small
                                                                className={`text-red-500 font-semibold`}>{approvalCommentError}</small>
                                                        )}
                                                        <textarea
                                                            id="comment"
                                                            name="comment"
                                                            rows={3}
                                                            value={approvalComment}
                                                            required={true}
                                                            onChange={(e) => {
                                                                setApprovalComment(e.target.value);
                                                                if (e.target.value === "") {
                                                                    setApprovalCommentError("Please comment on your decision!");
                                                                    return;
                                                                }
                                                                setApprovalCommentError("");
                                                            }}
                                                            className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                    <button  disabled={loading} onClick={(e) => handleWorkPlanApproval(e, "approved")}
                                                            className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                        {
                                                            loading ? "Submitting..." : "Approve"
                                                        }

                                                    </button>
                                                    <button disabled={loading} onClick={(e) => handleWorkPlanApproval(e, "rejected")}
                                                            className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                        {
                                                            loading ? "Submitting..." : "Reject"
                                                        }

                                                    </button>
                                                </div>

                                            </div>
                                        ) : selectedWorkPlan.manager_approval === "pending" && employeeI?.role === "division_head" ?
                                            (
                                                <div className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                    <label htmlFor="comment"
                                                           className="block text-sm/6 font-medium text-gray-900">
                                                        Comment
                                                    </label>
                                                    <div className="flex flex-col gap-0.5">
                                                        {approvalCommentError && (
                                                            <small
                                                                className={`text-red-500 font-semibold`}>{approvalCommentError}</small>
                                                        )}
                                                        <textarea
                                                            id="comment"
                                                            name="comment"
                                                            rows={3}
                                                            value={approvalComment}
                                                            required={true}
                                                            onChange={(e) => {
                                                                setApprovalComment(e.target.value);
                                                                if (e.target.value === "") {
                                                                    setApprovalCommentError("Please comment on your decision!");
                                                                    return;
                                                                }
                                                                setApprovalCommentError("");
                                                            }}
                                                            className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                        />
                                                    </div>
                                                </div>
                                                    <div
                                                        className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                        <button disabled={loading}
                                                                onClick={(e) => handleWorkPlanApproval(e, "approved")}
                                                                className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loading ? "Submitting..." : "Approve"
                                                            }

                                                        </button>
                                                        <button disabled={loading}
                                                                onClick={(e) => handleWorkPlanApproval(e, "rejected")}
                                                                className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                            {
                                                                loading ? "Submitting..." : "Reject"
                                                            }

                                                        </button>
                                                    </div>

                                                </div>
                                            ) : selectedWorkPlan.sm_approval === "pending" && employeeI?.role === "sub_department_head" ?
                                                (
                                                    <div
                                                        className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                        <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                            <label htmlFor="comment"
                                                                   className="block text-sm/6 font-medium text-gray-900">
                                                                Comment
                                                            </label>
                                                            <div className="flex flex-col gap-0.5">
                                                                {approvalCommentError && (
                                                                    <small
                                                                        className={`text-red-500 font-semibold`}>{approvalCommentError}</small>
                                                                )}
                                                                <textarea
                                                                    id="comment"
                                                                    name="comment"
                                                                    rows={3}
                                                                    value={approvalComment}
                                                                    required={true}
                                                                    onChange={(e) => {
                                                                        setApprovalComment(e.target.value);
                                                                        if (e.target.value === "") {
                                                                            setApprovalCommentError("Please comment on your decision!");
                                                                            return;
                                                                        }
                                                                        setApprovalCommentError("");
                                                                    }}
                                                                    className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                            <button disabled={loading}
                                                                    onClick={(e) => handleWorkPlanApproval(e, "approved")}
                                                                    className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                                {
                                                                    loading ? "Submitting..." : "Approve"
                                                                }

                                                            </button>
                                                            <button disabled={loading}
                                                                    onClick={(e) => handleWorkPlanApproval(e, "rejected")}
                                                                    className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                                {
                                                                    loading ? "Submitting..." : "Reject"
                                                                }

                                                            </button>
                                                        </div>

                                                    </div>
                                                ) : selectedWorkPlan.director_approval === "pending" && employeeI?.role === "department_head" ?
                                                    (
                                                        <div
                                                            className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                            <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                                <label htmlFor="comment"
                                                                       className="block text-sm/6 font-medium text-gray-900">
                                                                    Comment
                                                                </label>
                                                                <div className="flex flex-col gap-0.5">
                                                                    {approvalCommentError && (
                                                                        <small
                                                                            className={`text-red-500 font-semibold`}>{approvalCommentError}</small>
                                                                    )}
                                                                    <textarea
                                                                        id="comment"
                                                                        name="comment"
                                                                        rows={3}
                                                                        value={approvalComment}
                                                            required={true}
                                                            onChange={(e) => {
                                                                setApprovalComment(e.target.value);
                                                                if (e.target.value === "") {
                                                                    setApprovalCommentError("Please comment on your decision!");
                                                                    return;
                                                                }
                                                                setApprovalCommentError("");
                                                            }}
                                                            className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                        />
                                                    </div>
                                                </div>
                                                            <div
                                                                className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                                <button disabled={loading}
                                                                        onClick={(e) => handleWorkPlanApproval(e, "approved")}
                                                                        className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                                    {
                                                                        loading ? "Submitting..." : "Approve"
                                                                    }

                                                                </button>
                                                                <button disabled={loading}
                                                                        onClick={(e) => handleWorkPlanApproval(e, "rejected")}
                                                                        className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                                    {
                                                                        loading ? "Submitting..." : "Reject"
                                                                    }

                                                                </button>
                                                            </div>

                                                        </div>
                                                    ) : selectedWorkPlan.ceo_approval === "pending" && employeeI?.role === "ceo" && ((selectedWorkPlan.Employee.role === "division_head") || (selectedWorkPlan.Employee.role === "sub_department_head") || (selectedWorkPlan.Employee.role === "department_head") || (selectedWorkPlan.Employee.department_id === 6)) ?
                                                        (
                                                            <div
                                                                className={`w-full h-fit flex flex-col gap-4 items-end justify-center`}>
                                                                <div className={`w-72 h-fit flex flex-col gap-2`}>
                                                                    <label htmlFor="comment"
                                                                           className="block text-sm/6 font-medium text-gray-900">
                                                                        Comment
                                                                    </label>
                                                                    <div className="flex flex-col gap-0.5">
                                                                        {approvalCommentError && (
                                                                            <small
                                                                                className={`text-red-500 font-semibold`}>{approvalCommentError}</small>
                                                                        )}
                                                                    <textarea
                                                                        id="comment"
                                                                        name="comment"
                                                                        rows={3}
                                                                        value={approvalComment}
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            setApprovalComment(e.target.value);
                                                                            if (e.target.value === "") {
                                                                                setApprovalCommentError("Please comment on your decision!");
                                                                                return;
                                                                            }
                                                                            setApprovalCommentError("");
                                                                        }}
                                                                        className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                                    />
                                                                </div>
                                                            </div>
                                                                <div
                                                                    className={`w-full h-fit flex flex-row gap-4 justify-end items-center pr-4`}>
                                                                    <button disabled={loading}
                                                                            onClick={(e) => handleWorkPlanApproval(e, "approved")}
                                                                            className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                                        {
                                                                            loading ? "Submitting..." : "Approve"
                                                                        }

                                                                    </button>
                                                                    <button disabled={loading}
                                                                            onClick={(e) => handleWorkPlanApproval(e, "rejected")}
                                                                            className={`flex w-fit  justify-center rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold hover:text-red-500 hover:ring-2 hover:ring-red-500 leading-6 text-black hover:shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>
                                                                        {
                                                                            loading ? "Submitting..." : "Reject"
                                                                        }

                                                                    </button>
                                                                </div>

                                                            </div>
                                                        ) :
                                                        (

                                                            <div
                                                                className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                                                                <div
                                                                    className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                                                    <div
                                                                        className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid grid-cols-3 gap-x-6`}>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 text-center`}>Prepared
                                                                            By
                                                                        </div>

                                                                        {
                                                                            selectedWorkPlan.Employee.role === "admin_hod" ? (
                                                                                <>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 text-center relative`}>
                                                                                        {
                                                                                            selectedWorkPlan.sm_approval === "rejected" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-20`}>
                                                                                                    <FcCancel
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : selectedWorkPlan.sm_approval === "approved" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-20`}>
                                                                                                    <FcApproval
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-20`}>
                                                                                                    <PiDotsThree
                                                                                                        className={`w-5 h-5 text-gray-500`}/>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        Checked By
                                                                                    </div>


                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 text-center relative`}>
                                                                                        {
                                                                                            selectedWorkPlan.director_approval === "rejected" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-16`}>
                                                                                                    <FcCancel
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : selectedWorkPlan.director_approval === "approved" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-16`}>
                                                                                                    <FcApproval
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-16`}>
                                                                                                    <PiDotsThree
                                                                                                        className={`w-5 h-5 text-gray-500`}/>
                                                                                                </div>
                                                                                            )
                                                                                        }Approved By
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 text-center relative`}>
                                                                                        {
                                                                                            selectedWorkPlan.unit_head_approval === "rejected" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-20`}>
                                                                                                    <FcCancel
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : selectedWorkPlan.unit_head_approval === "approved" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-20`}>
                                                                                                    <FcApproval
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-20`}>
                                                                                                    <PiDotsThree
                                                                                                        className={`w-5 h-5 text-gray-500`}/>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        Checked By
                                                                                    </div>


                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 text-center relative`}>
                                                                                        {
                                                                                            selectedWorkPlan.manager_approval === "rejected" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-16`}>
                                                                                                    <FcCancel
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : selectedWorkPlan.manager_approval === "approved" ? (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-16`}>
                                                                                                    <FcApproval
                                                                                                        className={`w-5 h-5`}/>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={`absolute bottom-3 right-16`}>
                                                                                                    <PiDotsThree
                                                                                                        className={`w-5 h-5 text-gray-500`}/>
                                                                                                </div>
                                                                                            )
                                                                                        }Approved By
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }


                                                                    </div>
                                                                    <div
                                                                        className={`w-full bg-[#999999] py-[1px]`}></div>
                                                                    <div
                                                                        className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-3 gap-x-6`}>

                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.fname} ${selectedWorkPlan.Employee.mname.String} ${selectedWorkPlan.Employee.lname}`}</p>
                                                                            <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.position}`}</p>
                                                                        </div>
                                                                        {
                                                                            selectedWorkPlan.Employee.role === "admin_hod" ? (
                                                                                <>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.SM.fname} ${selectedWorkPlan.SM.mname.String} ${selectedWorkPlan.SM.lname}`}</p>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.SM.position}`}</p>
                                                                                    </div>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Director.fname} ${selectedWorkPlan.Director.mname.String} ${selectedWorkPlan.Director.lname}`}</p>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Director.position}`}</p>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.fname} ${selectedWorkPlan.UnitHead.mname.String} ${selectedWorkPlan.UnitHead.lname}`}</p>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.position}`}</p>
                                                                                    </div>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.fname} ${selectedWorkPlan.Manager.mname.String} ${selectedWorkPlan.Manager.lname}`}</p>
                                                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.position}`}</p>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }


                                                                    </div>

                                                                    <div
                                                                        className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid grid-cols-3 gap-x-6`}>
                                                                        <div
                                                                            className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                            <p className={`w-full text-center`}>{new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}</p>
                                                                        </div>

                                                                        {
                                                                            selectedWorkPlan.Employee.role === "admin_hod" ? (
                                                                                <>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        {
                                                                                            selectedWorkPlan.sm_approved_at.Valid && (

                                                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.sm_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        {
                                                                                            selectedWorkPlan.director_approved_at.Valid && (
                                                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.director_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                            )}
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        {
                                                                                            selectedWorkPlan.unit_head_approval_at.Valid && (

                                                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                                                        {
                                                                                            selectedWorkPlan.manager_approval_at.Valid && (
                                                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                                                            )}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                }
                            </>

                        ) : (
                            <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No workplans
                                    made on yet! Or still loading...</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default EmployeeSummary;