import React, {useEffect} from 'react';
import {
    generateWorkPlan2Pdf,
    generateWorkPlanPdf,
    getAllWorkPlansByDivision,
    getWorkPlanByEmployeeID,
    getWorkPlans, Header, TableDataFormat, TableFooter,
    WorkPlanData
} from "../../services/workPlanServiice";
import {useAuth} from "../../context/authContext";
import {useNavigate} from "react-router-dom";
import {useButtonTypeContext} from "../../context/button/buttonContext";
import {IoPersonCircle} from "react-icons/io5";
import {ButtonType} from "../../context/button/buttonTypes";
import {workPlanPdfGenFormat, workPlanReStruct} from "../../utils/workPlanRestruct";
import {BsFileEarmarkPdf} from "react-icons/bs";
import {TbFileIsr} from "react-icons/tb";
import {useWorkPlanToReportGen} from "../../context/workPlanToReport/workPlanToReportGenContext";
import {RiArrowGoBackLine} from "react-icons/ri";
import PdfRender from "../comps/pdfRender";
import {PDF_HOST} from "../../utils/api";
import {FcApproval, FcCancel} from "react-icons/fc";
import {PiDotsThree} from "react-icons/pi";
import {Department, getDepartment} from "../../services/departmentService";
import {getSubDepartment, SubDepartment} from "../../services/subDepartment";
import {Division, getDivison} from "../../services/divisionService";

const DivisionWorkPlan = () => {
    const [workPlans, setWorkPlans] = React.useState<WorkPlanData[]>([]);
    const [selectedWorkPlan, setSelectedWorkPlan] = React.useState<WorkPlanData|undefined>(undefined);
    const {employeeI} = useAuth();
    const {setWorkPlanI} = useWorkPlanToReportGen();
    const navigate = useNavigate();
    const [refresh, setRefresh] = React.useState(false);
    const [displayPdf, setDisplayPdf] = React.useState(false);

    const [currentDepartment, setCurrentDepartment] = React.useState<Department| undefined>(undefined);
    const [currentSubDepartment, setCurrentSubDepartment] = React.useState<SubDepartment| undefined>(undefined);

    const [currentDivision, setCurrentDivision] = React.useState<Division| undefined>(undefined);


    const [isSubDepartmentHead, setIsSubDepartmentHead] = React.useState<boolean>(false);
    const [isDepartmentHead, setIsDepartmentHead] = React.useState<boolean>(false);



    useEffect(() => {
        if (!employeeI) navigate("/login")
        fetchData().then();
    },[refresh]);

    const handleChangeWorkPlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const workPlan = workPlans.find((workPlan) => workPlan.id === value);
        if (!workPlan) return;
        setSelectedWorkPlan(workPlan);
    }

    const fetchData = async () => {
        if (!employeeI) {
            navigate("/login");
            return;
        }

        if (!employeeI.division) {
            // navigate("/login");
            return;
        }

        await getAllWorkPlansByDivision(employeeI.division, 'division').then(res => {
            if (res) {
                console.log(res)
                setWorkPlans(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedWorkPlan(
                    res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );
            }
        });


        await getDepartment(employeeI.department).then(res => {
            if (res) {
                setCurrentDepartment(res);
                if (res.director_id && res.director_id > 0) {
                    setIsDepartmentHead(true)
                }
            }
        })

        if (employeeI.sub_department && employeeI.sub_department > 0) {
            await getSubDepartment(employeeI.sub_department).then(res => {
                if (res) {
                    setCurrentSubDepartment(res);
                    if (res.employee_id.Valid && res.employee_id.Int64 > 0) {
                        setIsSubDepartmentHead(true)
                    }
                }
            })
        }

        await getDivison(employeeI.division).then(res => {
            if (res) {
                setCurrentDivision(res);
            }
        })

    }


    const handleGenerateWorkPlan =async (e: React.MouseEvent<HTMLDivElement>) => {
        if (!selectedWorkPlan) return;
        const header: Header = {
            owner: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
            department: selectedWorkPlan.Department.name.toUpperCase(),
            for: `${selectedWorkPlan.month.toUpperCase()} ${selectedWorkPlan.year}`.trim()
        }

        const data: TableDataFormat[] = workPlanPdfGenFormat(selectedWorkPlan.Tasks)



        if (isDepartmentHead && isSubDepartmentHead) {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Employee.position.toUpperCase(),
                    signature: selectedWorkPlan.Employee.signature.String,
                    date: `${new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}`
                },
                reviewed: {
                    name: `${selectedWorkPlan.SM.fname.toUpperCase()} ${selectedWorkPlan.SM.mname.Valid ? selectedWorkPlan.SM.mname.String.toUpperCase() : ""} ${selectedWorkPlan.SM.lname.toUpperCase()}`,
                    position: selectedWorkPlan.SM.position.toUpperCase(),
                    signature: selectedWorkPlan.SM.signature.String,
                    date: `${new Date(selectedWorkPlan.sm_approved_at.Time).toLocaleDateString("en-GB")}`
                },
                approved: {
                    name: `${selectedWorkPlan.Director.fname.toUpperCase()} ${selectedWorkPlan.Director.mname.Valid ? selectedWorkPlan.Director.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Director.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Director.position.toUpperCase(),
                    signature: selectedWorkPlan.Director.signature.String,
                    date: `${new Date(selectedWorkPlan.director_approved_at.Time).toLocaleDateString("en-GB")}`
                }
            }
            const res = await generateWorkPlanPdf(selectedWorkPlan.id, {
                header: header,
                data: data,
                footer: footer,
            });
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
        } else if (isSubDepartmentHead && !isDepartmentHead) {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Employee.position.toUpperCase(),
                    signature: selectedWorkPlan.Employee.signature.String,
                    date: `${new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}`
                },


                approved: {
                    name: `${selectedWorkPlan.SM.fname.toUpperCase()} ${selectedWorkPlan.SM.mname.Valid ? selectedWorkPlan.SM.mname.String.toUpperCase() : ""} ${selectedWorkPlan.SM.lname.toUpperCase()}`,
                    position: selectedWorkPlan.SM.position.toUpperCase(),
                    signature: selectedWorkPlan.SM.signature.String,
                    date: `${new Date(selectedWorkPlan.sm_approved_at.Time).toLocaleDateString("en-GB")}`
                }
            }
            const res = await generateWorkPlan2Pdf(selectedWorkPlan.id, {
                header: header,
                data: data,
                footer: footer,
            });
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
        }else if (!isSubDepartmentHead && isDepartmentHead) {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Employee.position.toUpperCase(),
                    signature: selectedWorkPlan.Employee.signature.String,
                    date: `${new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}`
                },


                approved: {
                    name: `${selectedWorkPlan.Director.fname.toUpperCase()} ${selectedWorkPlan.Director.mname.Valid ? selectedWorkPlan.Director.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Director.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Director.position.toUpperCase(),
                    signature: selectedWorkPlan.Director.signature.String,
                    date: `${new Date(selectedWorkPlan.director_approved_at.Time).toLocaleDateString("en-GB")}`
                }
            }
            const res = await generateWorkPlan2Pdf(selectedWorkPlan.id, {
                header: header,
                data: data,
                footer: footer,
            });
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
        } else {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Employee.position.toUpperCase(),
                    signature: selectedWorkPlan.Employee.signature.String,
                    date: `${new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}`
                },


                approved: {
                    name: `${selectedWorkPlan.Ceo.fname.toUpperCase()} ${selectedWorkPlan.Ceo.mname.Valid ? selectedWorkPlan.Ceo.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Ceo.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Ceo.position.toUpperCase(),
                    signature: selectedWorkPlan.Ceo.signature.String,
                    date: `${new Date(selectedWorkPlan.ceo_approved_at.Time).toLocaleDateString("en-GB")}`
                }
            }
            const res = await generateWorkPlan2Pdf(selectedWorkPlan.id, {
                header: header,
                data: data,
                footer: footer,
            });
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
        }

        setRefresh(!refresh);
    }


    const {setButtonType} = useButtonTypeContext()
    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 pt-20 pb-32 relative`}>
            {
                displayPdf && (
                    <div onClick={() => setDisplayPdf(false)} className={`absolute w-full h-full bg-black/40 z-20`}></div>
                )
            }
            {
                displayPdf && (
                    <div className={`absolute w-full h-full z-20`}>
                        <div className={`w-full h-full relative`}>
                            <div
                                onClick={() => setDisplayPdf(false)}
                                className={`absolute w-fit h-fit p-2 bg-black/40 left-5 top-16 z-20 rounded-full hover:-translate-x-0.5 cursor-pointer`}>
                                <RiArrowGoBackLine className="w-5 h-5  text-white" />
                            </div>
                            {selectedWorkPlan && <PdfRender pdf={PDF_HOST + selectedWorkPlan.file} />}

                        </div>
                        {/*Hello*/}
                        {/*{PDF_HOST + selectedWorkPlan?.file}*/}
                    </div>
                )
            }

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        {
                            currentDivision?.manager_id === employeeI?.user_id ? (
                                <>
                                    <p>{`${employeeI?.fname} ${employeeI?.mname} ${employeeI?.lname}`}</p>
                                    <p>{`${employeeI?.position}`}</p>
                                </>
                            ) : (
                                <>
                                    <p>{`${currentDivision?.name}`}</p>
                                </>
                            )
                        }

                        {/*<p>Performance Avg: 4.5</p>*/}
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4 items-center justify-center`}>
                    {
                        employeeI?.role === "division_head" ?
                            (
                                <div onClick={() => setButtonType(ButtonType.CreateWorkPlan)} className={`w-fit h-fit py-1.5 px-3 bg-white/90 rounded-md cursor-pointer text-lg font-semibold text-[#1c1c1c] shadow-sm hover:shadow hover:bg-white/50 shadow-stone-500/60`}>
                                    <p>Create WorkPlan</p>
                                </div>
                            ) :
                            (
                                <div></div>
                            )
                    }

                    {
                        workPlans.length > 0 && (

                            <select
                                onChange={handleChangeWorkPlan}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                                {
                                    workPlans.map((workPlan: WorkPlanData, i) => (
                                        <option key={i} value={workPlan.id}>{`${workPlan.month.slice(0,3)} - ${workPlan.year} Work Plan`}</option>
                                    ))
                                }
                            </select>
                        )
                    }

                </div>
            </div>

            {/*Work Plan*/}
            {
                selectedWorkPlan ? (
                    <>

                        <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                            <div
                                className={`w-fit text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-gray-500 font-semibold relative`}>
                                {`WorkPlan: ${selectedWorkPlan.work_plan_number}`}

                                {
                                    selectedWorkPlan.Employee.id === employeeI?.user_id && (
                                        <div
                                            onClick={() => {
                                                setButtonType(ButtonType.EditWorkPlan);
                                                setWorkPlanI(selectedWorkPlan);
                                            }}
                                            className={`absolute z-10 w-fit h-fit right-0 top-6 cursor-pointer`}>
                                            <p className={`text-xs text-orange-400 font-semibold`}>Edit</p>
                                        </div>

                                    )


                                }
                                {
                                    (
                                        ((selectedWorkPlan.ceo_approval === "approved")||  ((isSubDepartmentHead && selectedWorkPlan.sm_approval === "approved") &&
                                            (isDepartmentHead && selectedWorkPlan.director_approval === "approved")) || ( !isSubDepartmentHead && (isDepartmentHead && selectedWorkPlan.director_approval === "approved")) || ( !isDepartmentHead && (isSubDepartmentHead && selectedWorkPlan.sm_approval === "approved"))) && (selectedWorkPlan.Employee.id === employeeI?.user_id) &&
                                        (selectedWorkPlan.file.length > 0)
                                    )
                                        ?
                                        (
                                            <div
                                                onClick={() => setDisplayPdf(true)}
                                                className={`absolute z-10 w-fit h-fit right-0 bottom-6 cursor-pointer`}>
                                                <BsFileEarmarkPdf
                                                    className={`hover:text-orange-400 text-emerald-400 w-5`}/>
                                            </div>

                                        )
                                        : (
                                            <div></div>
                                        )
                                }
                                {
                                    ((selectedWorkPlan.sm_approval === "approved" && selectedWorkPlan.director_approval === "approved") || (selectedWorkPlan.department_id === 7 && selectedWorkPlan.ceo_approval === "approved") || (selectedWorkPlan.department_id === 6 && (selectedWorkPlan.sm_approval === "approved" && selectedWorkPlan.ceo_approval === "approved"))) && (selectedWorkPlan.Employee.id === employeeI?.user_id) && (selectedWorkPlan.file.length <= 0)

                                        ?
                                        (
                                            <div
                                                onClick={(e) => handleGenerateWorkPlan(e)}
                                                className={`absolute z-10 w-fit h-fit right-0 bottom-6 cursor-pointer`}>
                                                {/*<p className={`text-xs text-orange-400 font-semibold`}>Edit</p>*/}
                                                {/*<TbFileIsr className={`hover:text-emerald-400 text-gray-400 w-5`}/>*/}
                                                <p className={`text-xs hover:text-emerald-400 text-gray-400 font-semibold`}>Generate
                                                    Approved WorkPlan</p>
                                            </div>

                                        )
                                        : (
                                            <div></div>
                                        )
                                }
                            </div>
                            <div className={`w-fit h-fit`}>
                                {
                                    (selectedWorkPlan.sm_approval === "approved" && selectedWorkPlan.director_approval === "approved" && !selectedWorkPlan.is_report_generated) ?
                                        (
                                            <div
                                                onClick={() => {
                                                    setButtonType(ButtonType.GenerateReport);
                                                    setWorkPlanI(selectedWorkPlan);
                                                }}
                                                className={`w-fit h-fit py-1.5 px-3 bg-orange-500 rounded-md cursor-pointer text-lg font-semibold text-white shadow-sm hover:shadow hover:bg-orange-600 shadow-stone-500/60`}>
                                                <p>Generate Report</p>
                                            </div>
                                        ) :
                                        (
                                            <div></div>
                                        )
                                }
                            </div>
                        </div>


                        {
                            workPlanReStruct(selectedWorkPlan.Tasks).map((obj, index) => (

                                <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                    <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                                        <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                    </div>
                                    <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                        <div
                                            className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                            <div
                                                className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                <div className={`w-1/4 h-fit`}>Activity</div>
                                                <div
                                                    className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                    <div className={`w-full h-fit col-span-3`}>Target</div>
                                                    <div className={`w-full h-fit col-span-2`}>Unit of Measure</div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Resource
                                                    </div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Timeline
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                            {
                                                obj.activities.length >= 0 && (
                                                    obj.activities.map((activity, i) => (
                                                        <div key={i}
                                                             className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                            <div
                                                                className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                <div
                                                                    className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                    <p>{activity.activityName}</p>
                                                                </div>
                                                                {
                                                                    activity.tasks.length <= 0 ?
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                <div className={`w-fit h-fit`}>
                                                                                    <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                        targets a located for this
                                                                                        Activity.</p>
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                {
                                                                                    activity.tasks.map((task, i) => (

                                                                                        <div key={i}
                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                {task.name}
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.unit_of_measure}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.resource}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                <p className={`w-full text-center`}>{new Date(task.dead_line).toLocaleDateString("en-GB")}</p>
                                                                                                <div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>

                                                            <div
                                                                className={`w-full flex flex-row items-center`}>
                                                                <div
                                                                    className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                            </div>


                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className={`w-full h-fit flex flex-col justify-between gap-4 mt-16`}>
                            <div
                                className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                <div
                                    className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid ${((isDepartmentHead && isSubDepartmentHead)) ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                    <div className={`w-full h-fit col-span-1 text-center`}>Prepared By</div>

                                    {
                                        ((isDepartmentHead && isSubDepartmentHead)) ?
                                            (
                                                <>
                                                    <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                        {
                                                            selectedWorkPlan.sm_approval === "rejected" ? (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <FcCancel className={`w-5 h-5`}/>
                                                                </div>
                                                            ) : selectedWorkPlan.sm_approval === "approved" ? (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <FcApproval className={`w-5 h-5`}/>
                                                                </div>
                                                            ) : (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <PiDotsThree
                                                                        className={`w-5 h-5 text-gray-500`}/>
                                                                </div>
                                                            )
                                                        }

                                                        Checked By
                                                    </div>

                                                    <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                        {
                                                            selectedWorkPlan.director_approval === "rejected" ? (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <FcCancel className={`w-5 h-5`}/>
                                                                </div>
                                                            ) : selectedWorkPlan.director_approval === "approved" ? (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <FcApproval className={`w-5 h-5`}/>
                                                                </div>
                                                            ) : (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <PiDotsThree
                                                                        className={`w-5 h-5 text-gray-500`}/>
                                                                </div>
                                                            )
                                                        }

                                                        Approved By
                                                    </div>
                                                </>
                                            ) : (isSubDepartmentHead && !isDepartmentHead ) ? (
                                                <>
                                                    <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                        {
                                                            selectedWorkPlan.sm_approval === "rejected" ? (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <FcCancel className={`w-5 h-5`}/>
                                                                </div>
                                                            ) : selectedWorkPlan.sm_approval === "approved" ? (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <FcApproval className={`w-5 h-5`}/>
                                                                </div>
                                                            ) : (
                                                                <div className={`absolute bottom-3 right-20`}>
                                                                    <PiDotsThree className={`w-5 h-5 text-gray-500`}/>
                                                                </div>
                                                            )
                                                        }

                                                        Checked & Approved By
                                                    </div>
                                                </>
                                            ) : (!isSubDepartmentHead && isDepartmentHead ) ?(
                                                    <>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedWorkPlan.director_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedWorkPlan.director_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            Checked & Approved By
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                            {
                                                                selectedWorkPlan.ceo_approval === "rejected" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : selectedWorkPlan.ceo_approval === "approved" ? (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`absolute bottom-3 right-20`}>
                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            Checked & Approved By
                                                        </div>
                                                    </>
                                                )

                                    }

                                </div>
                                <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                <div
                                    className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${((isDepartmentHead && isSubDepartmentHead)) ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.fname} ${selectedWorkPlan.Employee.mname.String} ${selectedWorkPlan.Employee.lname}`}</p>
                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.position}`}</p>
                                    </div>


                                    {
                                        ((isDepartmentHead && isSubDepartmentHead)) ?
                                            (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.SM.fname} ${selectedWorkPlan.SM.mname.String} ${selectedWorkPlan.SM.lname}`}</p>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.SM.position}`}</p>
                                                    </div>

                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Director.fname} ${selectedWorkPlan.Director.mname.String} ${selectedWorkPlan.Director.lname}`}</p>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Director.position}`}</p>
                                                    </div>
                                                </>
                                            ) : (isSubDepartmentHead && !isDepartmentHead) ? (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.SM.fname} ${selectedWorkPlan.SM.mname.String} ${selectedWorkPlan.SM.lname}`}</p>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.SM.position}`}</p>
                                                    </div>
                                                </>
                                            ) : (!isSubDepartmentHead && isDepartmentHead) ? (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Director.fname} ${selectedWorkPlan.Director.mname.String} ${selectedWorkPlan.Director.lname}`}</p>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Director.position}`}</p>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Ceo.fname} ${selectedWorkPlan.Ceo.mname.String} ${selectedWorkPlan.Ceo.lname}`}</p>
                                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Ceo.position}`}</p>
                                                    </div>
                                                </>
                                            )

                                    }

                                </div>

                                <div
                                    className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${((isDepartmentHead && isSubDepartmentHead)) ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}</p>
                                    </div>

                                    {
                                        ((isDepartmentHead && isSubDepartmentHead)) ?
                                            (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        {
                                                            selectedWorkPlan.sm_approved_at.Valid && (
                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.sm_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                            )}
                                                    </div>

                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        {
                                                            selectedWorkPlan.director_approved_at.Valid && (

                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.director_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            ) : (isSubDepartmentHead && !isDepartmentHead) ? (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        {
                                                            selectedWorkPlan.sm_approved_at.Valid && (
                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.sm_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                            )}
                                                    </div>

                                                </>
                                            ) : (!isSubDepartmentHead && isDepartmentHead) ? (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        {
                                                            selectedWorkPlan.director_approved_at.Valid && (

                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.director_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div
                                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                        {
                                                            selectedWorkPlan.ceo_approved_at.Valid && (
                                                                <p className={`w-full text-center`}>{new Date(selectedWorkPlan.ceo_approved_at.Time).toLocaleDateString("en-GB")}</p>
                                                            )}
                                                    </div>
                                                </>
                                            )

                                    }

                                </div>
                            </div>
                        </div>
                    </>

                ) : (
                    <p className={`w-full text-center text-xs text-gray-400 font-semibold`}>No work plans added!, Please
                        create a division work plan</p>
                )
            }
        </div>
    );
};

export default DivisionWorkPlan;