import React from 'react';

const SubDepartmentGenerateReport = () => {
    return (
        <div>
            Coming soon ...
        </div>
    );
};

export default SubDepartmentGenerateReport;