import React, {useEffect, useState} from 'react';
import {IoPersonCircle} from "react-icons/io5";
import {FiPlusCircle} from "react-icons/fi";
import {getObjectives, ObjectiveData} from "../../services/objectiveService";
import {ActivityData, createActivityDB, getActivities} from "../../services/activityService";
import {MdOutlineCancel} from "react-icons/md";
import {CiSquareMinus} from "react-icons/ci";
import {BsClipboard2Plus} from "react-icons/bs";
import {createWorkPlan} from "../../services/workPlanServiice";
import {useAuth} from "../../context/authContext";
import {createTask} from "../../services/taskService";
import {ButtonType} from "../../context/button/buttonTypes";
import {useButtonTypeContext} from "../../context/button/buttonContext";
import {useNavigate} from "react-router-dom";
import {months} from "../../utils/scoresGraphReStructure";

export type WorkPlan = {
    objectives: Objective[];
};

export type Objective = {
    objectiveName: string;
    objectiveId: number;
    activities: Activity[];
};

export type Activity = {
    activityId: number;
    activityName: string;
    tasks: Task[];
}

export type Task = {
    taskName: string;
    unit: string;
    resource: string;
    start: string,
    cumulative?: number,
    achieved?: number,
}

const CreateEmployeeWorkPlan = () => {
    const [refresh, setRefresh] = useState(false);
    const {employeeI} = useAuth();
    const {setButtonType, setKind} = useButtonTypeContext()

    const [toggleObjective, setToggleObjective] = useState<boolean>(false);
    const [toggleActivity, setToggleActivity] = useState<boolean>(false);
    const [toggleTask, setToggleTask] = useState<boolean>(false);
    const [toggledActivityObjective, setToggledActivityObjective] = useState<number|null>(null);
    const [toggledTaskObjective, setToggledTaskObjective] = useState<number|null>(null);

    const [selectedObjective, setSelectedObjective] = useState<ObjectiveData | undefined>(undefined);
    const [selectedObjectiveId, setSelectedObjectiveId] = useState<number | undefined>(undefined);
    const [toggledTaskActivity, setToggledTaskActivity] = useState<number|null>(null);

    const [selectedActivity, setSelectedActivity] = useState<ActivityData | undefined>(undefined);
    const [selectedActivityId, setSelectedActivityId] = useState<number | undefined>(undefined);

    const [toggleWorkPlanDetails, setToggleWorkPlanDetails] = useState<boolean>(false);
    const [workPlanMonth, setWorkPlanMonth] = useState<string>("");
    const [workPlanYear, setWorkPlanYear] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    // Task
    const [target, setTarget] = useState<string>("");
    const [targetError, setTargetError] = useState<string>("");
    const [unit, setUnit] = useState<string>("");
    const [unitError, setUnitError] = useState<string>("");
    const [resource, setResource] = useState<string>("");
    const [resourceError, setResourceError] = useState<string>("");
    const [start, setStart] = useState<string>("");
    const [startError, setStartError] = useState<string>("");
    const [cumulative, setCumulative] = useState<string>("");
    const [cumulativeError, setCumulativeError] = useState<string>("");
    const [achieved, setAchieved] = useState<string>("");
    const [achievedError, setAchievedError] = useState<string>("");
    const [monthError, setMonthError] = useState<string>("");
    const [yearError, setYearError] = useState<string>("");

    const [objectives, setObjectives] = useState<ObjectiveData[]>([]);
    const [activities, setActivities] = useState<ActivityData[]>([]);

    const [workPlan, setWorkPlan] = React.useState<Objective[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!employeeI) navigate("/login")
        fetchData().then();
    },[])

    const handleObjectiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        setSelectedObjectiveId(value);
        const objective = objectives.find((objective) => objective.id === value);
        if (!objective) return;
        setSelectedObjective(objective);
    }

    const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if(value === "month") {
            setMonthError("Select a month");
            return;
        }
        setMonthError("")
        setWorkPlanMonth(value);
    }

    const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if(value === "year") {
            setYearError("Select a year");
            return;
        }
        setYearError("")

        setWorkPlanYear(e.target.value);
    }

    const handleActivityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        setSelectedActivityId(value);
        const activity = activities.find((activity) => activity.id === value);
        if (!activity) return;
        setSelectedActivity(activity);
    }

    const submitWorkPlan = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if(workPlanMonth.length < 0) return;
        let year = parseInt(workPlanYear);
        // console.log(year);
        if(!year) return;
        // console.log("Hello")
        if (!employeeI) return;

        let workPlanData = {
            employee_id: employeeI.user_id,
            month: workPlanMonth,
            year: year,
            type: "individual",
            department_id: employeeI.department,
            division_id: employeeI.division,
            sub_department_id: employeeI.sub_department,
            unit_id: employeeI.unit,
        };

        setLoading(true)
        const id = await createWorkPlan(workPlanData);
        if (!id) {
            setLoading(false);
            return;
        }
        workPlan.forEach((workPlan) => {
            workPlan.activities.forEach((activity) => {
                activity.tasks.forEach(async (task) => {
                    let tt = {
                        name: task.taskName,
                        work_plan_id: id,
                        achieved_percentage: task.achieved,
                        resource: task.resource,
                        activity_id: activity.activityId,
                        cumulative_percentage: task.cumulative,
                        dead_line: new Date(task.start),
                        is_added_task: false,
                        objective_id: workPlan.objectiveId,
                        remarks: "",
                        status: "",
                        unit_of_measure: task.unit
                    }
                    await createTask(tt).then()
                })
            })
        })

        setLoading(false)
        setWorkPlanMonth("")
        setWorkPlanYear("")
        setWorkPlan([])
        setKind("individual")
        setButtonType(ButtonType.WorkPlan)

    }

    const handleAddTask = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!toggledTaskObjective) return;
        if (!toggledTaskActivity) return;

        if(target.length <= 0) {
            setTargetError("Enter target value");
            return;
        }
        setTargetError("");


        let cum = parseInt(cumulative)
        if(cumulative.length > 0 && !cum) {
            setCumulativeError("Value has to be a number");
            return;
        }
        setCumulativeError("");

        let ach = parseInt(achieved)
        if(achieved.length > 0 && !ach) {
            setAchievedError("Value has to be a number");
            return;
        }
        setAchievedError("");

        if(unit.length <= 0) {
            setUnitError("Enter unit of measure (KPI) value");
            return;
        }
        setUnitError("");

        if(resource.length <= 0) {
            setResourceError("Enter resources value");
            return;
        }
        setResourceError("");

        if(start.length <= 0) {
            setStartError("Enter deadline value");
            return;
        }
        setStartError("");

        addTask(target, unit, resource, start, toggledTaskObjective, toggledTaskActivity, cum, ach);

        setToggledTaskActivity(null);
        setToggledTaskObjective(null);
        setToggleTask(false);
        setTargetError("");
        setTarget("");
        setCumulative("");
        setCumulativeError("");
        setAchieved("");
        setAchievedError("");
        setUnit("");
        setUnitError("");
        setResource("");
        setResourceError("");
        setStart("");
        setStartError("");
    }

    const fetchData = async () => {
        await getObjectives().then(res => {
            if (res) {
                setObjectives(res.filter(objective => !objective.is_complete));
            }
        }).catch(e => console.log(e));
        await getActivities().then(res => {
            if (res) {
                setActivities(res.filter(res => {
                    if (!res.is_complete) {
                        if (res.employee_id.Valid && res.employee_id.Int64 !== employeeI?.user_id) {
                            return false
                        }
                        return true;
                    }
                    return false
                }).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))
            }
        }).catch(e => console.log(e));
    }

    const [activityName, setActivityName] = useState("");
    const [activityDeadeLine, setActivityDeadeLine] = useState("");

    const [loadingAct, setLoadingAct] = useState(false);
    const createActivity = (e:  React.FormEvent<HTMLFormElement>, objId: number) => {
        e.preventDefault();
        if (activityName.length <= 0) return;
        if (activityDeadeLine.length <= 0) return;

        setLoadingAct(true);
        const ff = createActivityDB({
            name: activityName,
            dead_line: new Date(activityDeadeLine),
            employee_id: employeeI?.user_id,
            is_complete: false,
            objective_id: objId,
        }).then(res => {
            if (res) {
                // console.log(res)
                addActivity(res.id, res.name, res.objective_id);
            }
        });

        setToggleActivity(false);
        setActivityDeadeLine("");
        setActivityName("")
        setRefresh(!refresh);
        setLoadingAct(false);
    };

    const addObjective = (objectiveId: number, objectiveName: string) => {
        let objective = workPlan.find((objective) => objective.objectiveId === objectiveId);
        if (objective) return;
        setWorkPlan((prevState:Objective[]) => {
            return [...prevState, {objectiveName, objectiveId, activities:[]}]
        })
    }
    const removeObjective = (objectiveId: number) => {
        const objectives = [...workPlan];
        let j = objectives.findIndex((objective) => objective.objectiveId === objectiveId);
        objectives.splice(j, 1);
        setWorkPlan(objectives);
    }

    const addActivity = (activityId: number, activityName: string, objectiveId: number) => {
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        objective.activities = [...objective.activities, {activityId: activityId, activityName: activityName, tasks: []}];
        let j = objectives.findIndex(v => v.objectiveId === objectiveId);
        objectives.splice(j, 1);
        objectives.splice(j, 0, objective);
        setWorkPlan(objectives);
    }
    const removeActivity = (activityId: number, objectiveId: number) => {
        const objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }

        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        activities.splice(jA, 1);
        objective.activities = activities;
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);
    }

    const addTask = (taskName: string, unit: string, resource: string, start: string, objectiveId: number, activityId: number, cumulative?: number, achieved?: number) => {
        let objectives = [...workPlan];
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let activity = activities.find((activity) => activity.activityId === activityId);
        if (!activity) return;
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        let jA = activities.findIndex((activity) => activity.activityId === activityId);
        activity.tasks = [...activity.tasks, {taskName: taskName.trim(), unit: unit.trim(), resource: resource.trim(), start: start.trim(), cumulative, achieved}];
        activities.splice(jA, 1);
        activities.splice(jA,0,activity);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);
    }

    const removeTask = (taskIndex: number, activityId: number, objectiveId: number) => {
        let objectives = [...workPlan];
        let activity = objectives.find((objective) => objective.activities.find(a => a.activityId === activityId));
        if (!activity) {
            return;
        }
        let objective = objectives.find((objective) => objectiveId === objective.objectiveId);
        if (!objective) return;
        let activities = [...objective.activities];
        let act = activities.find((activity) => activity.activityId === activityId);
        if (!act) return;
        let tasks = [...act.tasks];
        let jA = activities.findIndex(a => a.activityId === activityId);
        let jO = objectives.findIndex(v => v.objectiveId === objectiveId);
        act.tasks = tasks;
        tasks.splice(taskIndex,1);
        activities.splice(jA,0,act);
        objectives.splice(jO, 1);
        objectives.splice(jO, 0, objective);
        setWorkPlan(objectives);

    }

    // console.log(workPlan);
    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 pt-20 pb-32 relative`}>
            {
                toggleTask && (
                    <div className={`absolute w-full h-fit flex flex-col justify-center items-center z-20 top-20`}>
                        <div
                            className={`w-1/2 h-fit  flex flex-col gap-4 px-4 pt-10 pb-10 mb-10 bg-white shadow-md shadow-stone-600/60 rounded-lg`}>
                            <form onSubmit={handleAddTask}>

                                <div className="space-y-8">
                                    <div className="border-b border-gray-900/10 pb-8">
                                        <h2 className="text-base/7 font-semibold text-gray-900">Task</h2>
                                        <p className="mt-1 text-sm/6 text-gray-600">
                                            This information gives a clear summary about the task you going to work on.
                                        </p>

                                        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="col-span-full">
                                                <label htmlFor="target" className="block text-sm/6 font-medium text-gray-900">
                                                    Target
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {targetError.length > 0 &&  (
                                                        <small className={`text-red-500 font-semibold`}>{targetError}</small>
                                                    )}
                                                    <textarea
                                                        id="target"
                                                        name="target"
                                                        rows={3}
                                                        value={target}
                                                        required={true}
                                                        onChange={(e) => {
                                                            setTarget(e.target.value);
                                                            if (e.target.value === "") {
                                                                setTargetError("Enter target value");
                                                                return;
                                                            }
                                                            setTargetError("");
                                                        }}
                                                        className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-b border-gray-900/10 pb-12">
                                        <p className="mt-1 text-sm/6 text-gray-600">In case the task builds on a previous task.</p>
                                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="Cumulative"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Cumulative Score (%)
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {cumulativeError.length > 0 &&  (
                                                        <small className={`text-red-500 font-semibold`}>{cumulativeError}</small>
                                                    )}
                                                    <input
                                                        id="cumulative"
                                                        name="cumulative"
                                                        value={cumulative}
                                                        onChange={(e) => {
                                                            setCumulative(e.target.value)
                                                        }}
                                                        type="text"
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="archieved"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Achieved Score (%)
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {achieved.length > 0 &&  (
                                                        <small className={`text-red-500 font-semibold`}>{achievedError}</small>
                                                    )}
                                                    <input
                                                        id="archieved"
                                                        name="archieved"
                                                        value={achieved}
                                                        onChange={(e) => {
                                                            setAchieved(e.target.value)
                                                        }}
                                                        type="text"
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4">
                                                <label htmlFor="unit" className="block text-sm/6 font-medium text-gray-900">
                                                    Unit of Measure
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {unitError.length > 0 &&  (
                                                        <small className={`text-red-500 font-semibold`}>{unitError}</small>
                                                    )}
                                                    <input
                                                        id="unit"
                                                        name="unit"
                                                        value={unit}
                                                        onChange={(e)=> setUnit(e.target.value)}
                                                        type="text"
                                                        required={true}
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4">
                                                <label htmlFor="resources"
                                                       className="block text-sm/6 font-medium text-gray-900">
                                                    Resources
                                                </label>
                                                <p className="mt-1 text-sm/6 text-gray-600">Separate your resources with commas e.g (CEO,PDN,PD,DFA).</p>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {resourceError.length > 0 && (
                                                        <small
                                                            className={`text-red-500 font-semibold`}>{resourceError}</small>
                                                    )}
                                                    <input
                                                        id="resources"
                                                        name="resources"
                                                        value={resource}
                                                        onChange={(e) => setResource(e.target.value)}
                                                        type="text"
                                                        required={true}
                                                        className="block outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 px-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-4 sm:col-start-1">
                                                <label htmlFor="deadLine" className="block text-sm/6 font-medium text-gray-900">
                                                    Dead Line
                                                </label>
                                                <div className="mt-2 flex flex-col gap-0.5">
                                                    {startError.length > 0 &&  (
                                                        <small className={`text-red-500 font-semibold`}>{startError}</small>
                                                    )}
                                                    <input
                                                        id="deadLine"
                                                        name="deadLine"
                                                        value={start}
                                                        onChange={(e)=>setStart(e.target.value)}
                                                        type="date"
                                                        min={new Date().toISOString().split("T")[0]}
                                                        required={true}
                                                        className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setToggledTaskActivity(null);
                                            setToggledTaskObjective(null);
                                            setToggleTask(false);
                                            setTargetError("");
                                            setTarget("");
                                            setCumulative("");
                                            setCumulativeError("");
                                            setAchieved("");
                                            setAchievedError("");
                                            setUnit("");
                                            setUnitError("");
                                            setResource("");
                                            setResourceError("");
                                            setStart("");
                                            setStartError("");

                                        }}
                                        type="button" className="text-sm/6 font-semibold text-gray-900 border-0 outline-0 focus:outline-0 sm:text-sm">
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold border-0 text-white shadow-sm hover:bg-orange-500 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Add Task
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <p>{`${employeeI?.fname.toUpperCase()} ${employeeI?.mname?.toUpperCase()} ${employeeI?.lname.toUpperCase()}`}</p>
                        <p>{`${employeeI?.position}`}</p>
                        {/*<p>Performance Avg: 4.5</p>*/}
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4 items-center justify-center`}>
                {
                        (!toggleWorkPlanDetails && workPlanYear.length <= 0) && (
                            <div onClick={() => setToggleWorkPlanDetails(true)}
                                 className={`w-fit h-fit py-1.5 px-3 bg-white/90 rounded-md cursor-pointer text-lg font-semibold text-[#1c1c1c] shadow-sm hover:shadow hover:bg-white/50 shadow-stone-500/60`}>
                                <p>Enter Details</p>
                            </div>
                        )
                    }
                    {
                        workPlanYear.length > 0 && (
                            <p className={`text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>{`${workPlanMonth.slice(0,3)} - ${workPlanYear} Work Plan`}</p>
                        )
                    }
                    {
                        toggleWorkPlanDetails && (
                            <div
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c] flex flex-col gap-4`}>
                                <div className={`w-56 h-fit flex flex-col gap-0.5`}>
                                    <label htmlFor="month"
                                           className="block text-sm/6 font-medium text-gray-900">
                                        Month
                                    </label>
                                    {monthError.length > 0 &&  (
                                        <small className={`text-red-500 font-semibold`}>{monthError}</small>
                                    )}
                                    <div className="flex flex-col gap-0.5">

                                        <select
                                            id="month"
                                            onChange={handleMonthChange}
                                            className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}>
                                            <option value={"month"}>Month</option>
                                            {
                                                months.map((month) => (
                                                    <option value={month}
                                                            key={month}>{month}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className={`w-56 h-fit flex flex-col gap-0.5`}>
                                    <label htmlFor="year"
                                           className="block text-sm/6 font-medium text-gray-900">
                                        Year
                                    </label>
                                    {yearError.length > 0 &&  (
                                        <small className={`text-red-500 font-semibold`}>{yearError}</small>
                                    )}
                                    <div className="flex flex-col gap-0.5">

                                        <select
                                            id="year"
                                            onChange={handleYearChange}
                                            className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}>
                                            <option value={"year"}>Select Year</option>
                                            <option value={"2025"}>2025</option>
                                            <option value={"2024"}>2024</option>
                                            <option value={"2023"}>2023</option>
                                        </select>

                                    </div>
                                </div>
                                <button onClick={() => {
                                    if (!workPlanMonth) return;
                                    if (!parseInt(workPlanYear)) return;
                                    setToggleWorkPlanDetails(false);
                                }}
                                        className={`flex w-fit self-end justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Add
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className={`w-full flex flex-col gap-4 justify-between items-start`}>
                {
                    workPlan.length <= 0 ?
                        (<p>Add an Objective</p>) :
                        (
                            workPlan.map((obj) => (
                                <div
                                    key={obj.objectiveId}
                                    className={`w-full h-fit flex flex-col gap-4 justify-center`}>
                                    <div
                                        className={`w-fit h-fit flex flex-row justify-between items-center relative pr-8`}>
                                    <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                        <div onClick={
                                            () => removeObjective(obj.objectiveId)
                                        }
                                             className={`absolute w-fit h-fit bg-white/60 shadow-sm shadow-500/40 rounded-full p-0.5 right-0 top-0`}>
                                        <MdOutlineCancel
                                                className={`text-[#1c1c1c]/60 w-5 h-5 hover:text-[#1c1c1c]/80`}/>
                                        </div>
                                    </div>
                                    <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                        <div
                                            className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                            <div
                                                className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                <div className={`w-1/4 h-fit`}>Activity</div>
                                                <div className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                    <div className={`w-full h-fit col-span-3`}>Target</div>
                                                    <div className={`w-full h-fit col-span-2`}>Unit of Measure</div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Resource</div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Timeline</div>
                                                </div>
                                            </div>
                                            {
                                                obj.activities.length === 0 ?
                                                    (
                                                        <div className={`w-full flex flex-row items-center`}>
                                                            <div
                                                                onClick={() => {
                                                                    setToggleActivity(true)
                                                                    setToggledActivityObjective(obj.objectiveId)
                                                                }}
                                                                className={`w-fit h-fit`}>
                                                                <FiPlusCircle
                                                                    className={`text-[#999999]/80 w-5 h-5 hover:text-[#999999]`}/>

                                                            </div>
                                                            <div className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <div className={`w-full flex flex-row items-center`}>
                                                            <div
                                                                className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                        </div>
                                                    )
                                            }

                                            {
                                                obj.activities.length >= 0 && (
                                                    obj.activities.map((activity, i) => (
                                                        <div key={i} className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                            {
                                                                activity.tasks.length > 0 && (
                                                                    <div onClick={() => {
                                                                        setToggleTask(true)
                                                                        console.log("Clicked")
                                                                        setToggledTaskObjective(obj.objectiveId)
                                                                        setToggledTaskActivity(activity.activityId)
                                                                    }}
                                                                        className={`absolute w-fit h-full right-0 flex flex-col justify-center z-20`}>
                                                                        <BsClipboard2Plus
                                                                            className={`w-5 h-5 text-[#1c1c1c]/60 hover:text-[#1c1c1c]/80`}/>
                                                                    </div>
                                                                )
                                                            }

                                                            <div
                                                                className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                <div
                                                                    className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                    <div onClick={() => {
                                                                        removeActivity(activity.activityId, obj.objectiveId)
                                                                    }}
                                                                         className={`w-fit h-fit self-start`}>
                                                                        <CiSquareMinus
                                                                            className={`w-5 h-5 text-[#1c1c1c]/60 hover:text-[#1c1c1c]/80`}/>
                                                                    </div>
                                                                    <p>{activity.activityName}</p>
                                                                </div>
                                                                {
                                                                    activity.tasks.length <= 0 ?
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                <div onClick={() => {
                                                                                    setToggleTask(true)
                                                                                    setToggledTaskObjective(obj.objectiveId)
                                                                                    setToggledTaskActivity(activity.activityId)
                                                                                }}
                                                                                     className={`w-fit h-fit`}>
                                                                                    <p className={`w-fit h-fit rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 cursor-pointer`}>Add
                                                                                        Target</p>
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                {
                                                                                    activity.tasks.map((task, i) => (

                                                                                        <div key={i} className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                <div onClick={() => {
                                                                                                    removeTask(i, activity.activityId, obj.objectiveId)
                                                                                                }}
                                                                                                     className={`w-fit h-fit self-start`}>
                                                                                                    <CiSquareMinus
                                                                                                        className={`w-5 h-5 text-[#1c1c1c]/60 hover:text-[#1c1c1c]/80`}/>
                                                                                                </div>
                                                                                                {task.taskName}
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.unit}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.resource}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                <p className={`w-full text-center`}>{task.start}</p>
                                                                                                <div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                            {
                                                                obj.activities.length - 1 === i ?
                                                                    (
                                                                        <div
                                                                            className={`w-full flex flex-row items-center`}>
                                                                            <div
                                                                                onClick={() => {
                                                                                    setToggleActivity(true)
                                                                                    setToggledActivityObjective(obj.objectiveId)
                                                                                }}
                                                                                className={`w-fit h-fit`}>
                                                                                <FiPlusCircle
                                                                                    className={`text-[#999999]/80 w-5 h-5 hover:text-[#999999]`}/>

                                                                            </div>
                                                                            <div className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                        </div>
                                                                    ) :
                                                                    (
                                                                        <div
                                                                            className={`w-full flex flex-row items-center`}>
                                                                            <div
                                                                                className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    ))
                                                )
                                            }

                                            {
                                                (toggleActivity && toggledActivityObjective === obj.objectiveId) && (
                                                    <div className={`w-fit h-fit flex flex-row items-center gap-8`}>
                                                        <div className={`w-fit h-fit flex flex-col gap-4 justify-center`}>
                                                            <select
                                                                onChange={handleActivityChange}
                                                                className={`w-fit ring-1 ring-gray-500 rounded-2xl px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                                                                <option>Company Activity</option>
                                                                {
                                                                    activities.filter(act => act.objective_id === obj.objectiveId).map((activity) => (
                                                                        <option value={activity.id}
                                                                                key={activity.id}>{activity.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <button onClick={() => {
                                                                if (!selectedActivity) return;
                                                                addActivity(selectedActivity.id, selectedActivity.name, obj.objectiveId);
                                                                setSelectedActivityId(undefined);
                                                                setSelectedActivity(undefined);
                                                                setToggleActivity(false);
                                                            }}
                                                                    className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Add
                                                            </button>

                                                        </div>
                                                        <p className={`text-2xl font-bold text-gray-500`}>OR</p>
                                                        <div className={`w-fit h-fit`}>
                                                            <form onSubmit={(e)=>{createActivity(e,obj.objectiveId)}}
                                                                className={`w-60 h-fit p-4 ring-1 ring-stone-500 rounded-xl flex flex-col gap-4`}>
                                                                <p className={`text-sm/6 text-gray-600 text-wrap`}>Add
                                                                    your own Activity fitting of this Objective with the
                                                                    guidance of your supervisor.</p>
                                                                <div
                                                                    className="grid grid-cols-1 gap-y-4 sm:grid-cols-6">
                                                                    <div className="col-span-full">
                                                                        <label htmlFor="activityNamae"
                                                                               className="block text-sm/6 font-medium text-gray-900">
                                                                            Activity
                                                                        </label>
                                                                        <div className="mt-1 flex flex-col gap-0.5">
                                                                            <textarea
                                                                                id="activityNamae"
                                                                                name="activityNamae"
                                                                                rows={2}
                                                                                value={activityName}
                                                                                required={true}
                                                                                onChange={(e) => {
                                                                                    setActivityName(e.target.value);
                                                                                }}
                                                                                className={`block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="sm:col-span-4 sm:col-start-1">
                                                                        <label htmlFor="deadLineActivity"
                                                                               className="block text-sm/6 font-medium text-gray-900">
                                                                            Dead Line
                                                                        </label>
                                                                        <div className="mt-1 flex flex-col gap-0.5">
                                                                            <input
                                                                                id="deadLineActivity"
                                                                                name="deadLineActivity"
                                                                                value={activityDeadeLine}
                                                                                onChange={(e) => setActivityDeadeLine(e.target.value)}
                                                                                type="date"
                                                                                min={new Date().toISOString().split("T")[0]}
                                                                                required={true}
                                                                                className="block px-4 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    disabled={loadingAct}
                                                                    type="submit"
                                                                    className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold border-0 text-white shadow-sm hover:bg-orange-500 outline-0 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                >
                                                                    Add Activity
                                                                </button>

                                                            </form>
                                                        </div>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                }
                {
                    !toggleObjective && (
                        <div
                            onClick={() => setToggleObjective(true)}
                            className={`w-fit h-fit flex flex-row gap-4 py-1.5 px-3 hover:shadow hover:bg-white/50 shadow-stone-500/60 cursor-pointer items-center justify-center`}>
                            <p className={`text-base 2xl:text-lg font-semibold text-[#1c1c1c]`}>Add Objective</p>
                            <FiPlusCircle className={`w-5 h-5 font-semibold text-[#1c1c1c]`}/>
                        </div>

                    )
                }
                {
                    toggleObjective && (
                        <div className={`w-fit h-fit flex flex-col gap-4 justify-center`}>
                            <select
                                onChange={handleObjectiveChange}
                                className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                                <option>Company Objectives</option>
                                {
                                    objectives.map((objective) => (
                                        <option value={objective.id} key={objective.id}>{objective.name}</option>
                                    ))
                                }
                            </select>
                            <button onClick={() => {
                                if (!selectedObjective) return;
                                addObjective(selectedObjective.id, selectedObjective.name);
                                setSelectedObjectiveId(undefined);
                                setSelectedObjective(undefined);
                                setToggleObjective(false);
                            }}
                                    className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>Add
                            </button>
                        </div>
                    )
                }
            </div>
            {
                workPlan.length > 0 && (

                    <div className={`w-full h-fit flex flex-row gap-4 justify-end items-center`}>
                        <button
                            disabled={loading}
                            onClick={(e) => submitWorkPlan(e)}
                                className={`flex w-fit  justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}>{loading ? "Submitting..." : "Submit"}
                        </button>
                    </div>
                )
            }
        </div>
    );
};

export default CreateEmployeeWorkPlan;